import { defineMessages } from "react-intl";

export const SECTIONS = defineMessages({
  campaignName: {
    id: "08f960b5-959a-4768-8feb-f0855263f9df",
    defaultMessage: "Referral campaign name",
  },
  campaignId: {
    id: "f7333b30-b211-4a06-b9ce-d0991899437d",
    defaultMessage: "Referral campaign ID",
  },
  code: {
    id: "058e986c-9feb-4091-8594-0398b94d24b1",
    defaultMessage: "Referral code",
  },
  transactionName: {
    id: "e5aad782-c170-4acc-860c-f0a70f2ef168",
    defaultMessage: "Transaction name",
  },
  transactionType: {
    id: "47476e73-0b8e-4ef9-9be1-a2003698d7be",
    defaultMessage: "Transaction type",
  },
  accessPortal: {
    id: "7243a17c-517a-42c7-9495-7db7261ed9f3",
    defaultMessage: "Access portal",
  },
  accessPin: {
    id: "012820b4-0e06-457b-a1fc-8a1d8a7b7e2d",
    defaultMessage: "Access PIN",
  },
  accessId: {
    id: "00bc82d9-b5f6-43cb-a9e3-18a7cffa4658",
    defaultMessage: "Access ID",
  },
  transactionOrganization: {
    id: "dc01b178-bc03-45ef-897f-83d50091882d",
    defaultMessage: "Organization",
  },
  status: {
    id: "24dec161-97cf-4582-84a3-abccede12e0f",
    defaultMessage: "Status",
  },
  fileNumber: {
    id: "bedeff7e-beda-40ff-ab89-9e93047a4b34",
    defaultMessage: "File number",
  },
  loanNumber: {
    id: "c34a05eb-5e75-4242-8092-f92de54c4796",
    defaultMessage: "Loan number",
  },
  externalId: {
    id: "d534f8e5-9855-4b04-b4d6-bbcaf8484335",
    defaultMessage: "External ID",
  },
  message: {
    id: "2db425b1-a6f7-4aff-b7b3-ab10122d926c",
    defaultMessage: "Message",
  },
  signature: {
    id: "8b0b9830-fc62-411d-b2d8-2a1c08648214",
    defaultMessage: "Signature",
  },
  payment: {
    id: "23546313-7f77-4d9b-9f04-e9e7f1019d3f",
    defaultMessage: "Payment",
  },
  propertyAddress: {
    id: "33127d20-e55a-445d-a02d-c193a2aeec1e",
    defaultMessage: "Property address",
  },
  lenderName: {
    id: "0c966ab1-8542-4db1-b729-47082a855d2d",
    defaultMessage: "Lender",
  },
  titleUnderwriterName: {
    id: "cb4a7ca4-81e6-4ee0-a057-06ce9e9f086b",
    defaultMessage: "Title underwriter",
  },
  transactionId: {
    id: "254c19fe-aa25-4048-b1ee-2ebf04e0ac5d",
    defaultMessage: "Transaction ID",
  },
  creationSource: {
    id: "33115e32-3c8c-4a25-87b6-ceeab2286850",
    defaultMessage: "Source",
  },
  notaryAssignment: {
    id: "0959e553-c927-4d63-af3d-89a918d2f215",
    defaultMessage: "Notary assignment",
  },
  recordingLocation: {
    id: "f2999207-75f7-4124-a8fb-c72f03671ac5",
    defaultMessage: "Recording location",
  },
  meetings: {
    id: "1d230295-17bb-4090-b11a-e56cb8ffd2bb",
    defaultMessage: "Transaction meetings",
  },
  credibleWitness: {
    id: "9fe81810-57c9-424f-8afe-2d3f925d5299",
    defaultMessage: "Credible witness",
  },
  transactionWitness: {
    id: "2d8ffa0b-5ad8-49bc-847b-718e38100ca6",
    defaultMessage: "Transaction witness",
  },
  titleAgency: {
    id: "c22059c3-2222-45a4-830a-77f9cdb17a4e",
    defaultMessage: "Title agency",
  },
});
