import type { InjectedFormProps } from "redux-form";

import { emptyValue, oneOfTwoFieldsRequired } from "errors/form";
import { invalidEmail, nonTitleAgentEmailError } from "errors/account";
import {
  type TitleAgentLookup,
  TitleLookupErrors,
} from "common/mortgage/transactions/edit/title_agency_service";
import { validationRules as addressValidation } from "common/form/sub_forms/address";
import { validateEmailFormatFunction } from "validators/account";
import { validateIf, validatePresence, validatePresenceOfOne } from "validators/form";
import { composeValidators } from "util/form";

import type { TransactionEdit_transaction_OrganizationTransaction_pointsOfContact as PointOfContact } from "../transaction_edit_query.graphql";

const FIRST_NAME = Symbol("First Name");
const LAST_NAME = Symbol("Last Name");

type FormValues = {
  pointsOfContact: PointOfContact[];
  titleAgency: string | null;
};

type ValidationProps = {
  titleAgentLookup: TitleAgentLookup | null;
  isFullRON: boolean;
  hasCollaboratorSection: boolean;
  isRealEstateCollabEnabled: boolean;
};
type InjectedProps = InjectedFormProps<FormValues, ValidationProps>;
type Props = ValidationProps & InjectedProps & { formValues: FormValues };

export const validationRules = (values: FormValues, props: Props) =>
  composeValidators(
    validateIf({
      condition: () =>
        Boolean(
          props.hasCollaboratorSection && props.titleAgentLookup && !props.titleAgentLookup.error,
        ),
      validation: validatePresence({ field: "titleAgency", label: "Title Agency" }),
    }),
    validateIf({
      condition: () =>
        props.hasCollaboratorSection && props.isFullRON && props.isRealEstateCollabEnabled,
      validation: validatePresenceOfOne({
        primaryField: "titleAgency",
        secondaryField: "pointsOfContact[0].email",
        primaryLabel: "Title agency",
        secondaryLabel: "Title agent",
      }),
    }),
    validateIf({
      condition: () =>
        props.hasCollaboratorSection && props.isFullRON && !props.isRealEstateCollabEnabled,
      validation: validatePresence({
        field: "titleAgency",
        label: "Title Agency",
      }),
    }),
    addressValidation(values, props, "pointsOfContact[0].organizationAddress."),
  );

const validateEmail = (
  pointOfContact: PointOfContact,
  titleAgency: string,
  isFullRON: boolean,
  titleAgentLookup: TitleAgentLookup | null,
  isRealEstateCollabEnabled: boolean,
) => {
  const email = pointOfContact.email;
  const phoneNumber = pointOfContact.phoneNumber;
  const firstName = pointOfContact.firstName;
  const lastName = pointOfContact.lastName;
  if (isFullRON && isRealEstateCollabEnabled && !email && !titleAgency) {
    return oneOfTwoFieldsRequired({ label1: "Title agency", label2: "Title agent" });
  }

  if ((firstName || lastName || phoneNumber) && !email) {
    return emptyValue({ label: "Email" });
  }

  if (email && !validateEmailFormatFunction(email)) {
    return invalidEmail({ label: "Email" });
  }

  if (email && titleAgentLookup?.error === TitleLookupErrors.NOT_TITLE_AGENT) {
    return nonTitleAgentEmailError();
  }
};

const validateIfEmailPresent = (email: string | null, value: string, label: string) => {
  if (email && !value) {
    return emptyValue({ label });
  }
};

// we only want to validate any of the name values if one of them exists
const validateIfNamePresent = (firstName: string, lastName: string, label: symbol) => {
  if (label === FIRST_NAME && !firstName && lastName) {
    return emptyValue({ label: "First Name" });
  } else if (label === LAST_NAME && firstName && !lastName) {
    return emptyValue({ label: "Last Name" });
  }
  return undefined;
};

const validateTitleCollabContact = (
  pointOfContact: PointOfContact,
  titleAgency: string,
  isFullRON: boolean,
  titleAgentLookup: TitleAgentLookup | null,
  isRealEstateCollabEnabled: boolean,
) => {
  const isSharedInboxEmail =
    titleAgentLookup &&
    pointOfContact.email &&
    titleAgentLookup.inboxEmail?.toLowerCase() === pointOfContact.email.toLowerCase();

  return {
    email: validateEmail(
      pointOfContact,
      titleAgency,
      isFullRON,
      titleAgentLookup,
      isRealEstateCollabEnabled,
    ),
    firstName: isSharedInboxEmail
      ? validateIfNamePresent(pointOfContact.firstName, pointOfContact.lastName, FIRST_NAME)
      : validateIfEmailPresent(pointOfContact.email, pointOfContact.firstName, "First Name"),
    lastName: isSharedInboxEmail
      ? validateIfNamePresent(pointOfContact.firstName, pointOfContact.lastName, LAST_NAME)
      : validateIfEmailPresent(pointOfContact.email, pointOfContact.lastName, "Last Name"),
  };
};

export default validateTitleCollabContact;
