import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";

import { useWatch } from "common/core/form";
import { Payer } from "graphql_globals";
import { TRANSACTION_LEVEL_PAYER_CONFIGURATION } from "constants/feature_gates";
import { isFeatureEnabled } from "util/feature_detection";
import { isNotaryNST } from "common/notary/capacity";

import type { SectionContract, SectionComponentProps } from "../../form";
import { Card, ConfiguredRadioGroup, SectionHeader, downgradeDisplay } from "../../common";
import type { OrgPayment } from "./transaction_fragment.graphql";
import type { OrgAccountPayment } from "./org_account_payment_fragment.graphql";
import type { OrgPaymentUser } from "./org_payment_user_fragment.graphql";
import Styles from "./index.module.scss";
import { PaymentModal } from "./payment_modal";

const MESSAGES = defineMessages({
  payment: {
    id: "d3bc4d04-2059-470c-addc-b979616c3d90",
    defaultMessage: "Payment",
  },
});

const CONFIGS = {
  payer: "payer",
} as const;
const FORM_FIELDS = {
  payer: "payer",
} as const;

export type OrgPaymentFormValues = {
  [FORM_FIELDS.payer]: Payer;
};
type OrgPaymentSubmitData = {
  payer: Payer;
};

function getAccountPaymentMethod({
  defaultPayer,
  defaultPaymentSource,
}: {
  defaultPayer: OrgAccountPayment["defaultPayer"];
  defaultPaymentSource: OrgAccountPayment["defaultPaymentSource"];
}) {
  if (defaultPayer !== Payer.CUSTOMER) {
    return defaultPayer;
  }

  switch (defaultPaymentSource?.__typename) {
    case "AchAccount":
      return Payer.ORGANIZATION_ACH;
    default:
      return Payer.ORGANIZATION;
  }
}

function OrgPaymentSection({
  form,
  transaction,
  organization,
  config,
}: SectionComponentProps<OrgPayment, OrgAccountPayment, unknown>) {
  const [showModal, setShowModal] = useState(false);
  const { control, setValue } = form;
  const payer = useWatch({ control, name: "payer" });

  const { defaultPayer, defaultPaymentSource } = organization;

  const intl = useIntl();

  useEffect(() => {
    if (payer === Payer.ORGANIZATION && !defaultPaymentSource) {
      setShowModal(true);
    }
  }, [payer]);

  return (
    <>
      {showModal && (
        <PaymentModal
          onClose={(orgPaymentAdded?: boolean) => {
            setShowModal(false);
            if (!orgPaymentAdded) {
              setValue("payer", Payer.CUSTOMER);
            }
          }}
        />
      )}
      <SectionHeader iconName="card">{intl.formatMessage(MESSAGES.payment)}</SectionHeader>
      <Card title={intl.formatMessage(MESSAGES.payment)}>
        <ConfiguredRadioGroup
          radioGroupLabel={
            <FormattedMessage
              id="5a59760e-7a25-49c1-89d3-049e92da1a4b"
              defaultMessage="Who should pay after the documents are completed?"
            />
          }
          config={config}
          configField={CONFIGS.payer}
          form={form}
          className={Styles.radioGroup}
          name={FORM_FIELDS.payer}
          radioButtons={[
            {
              label: (
                <FormattedMessage
                  id="3f2641ff-7837-456d-83c3-5c9c7aa04725"
                  defaultMessage="Signer"
                />
              ),
              value: Payer.CUSTOMER,
            },
            {
              label: (
                <FormattedMessage
                  id="61fe0f8e-52d2-4fe4-ae36-302139c7f825"
                  defaultMessage="{orgName}"
                  values={{ orgName: transaction.organization.name }}
                />
              ),
              value: getAccountPaymentMethod({ defaultPayer, defaultPaymentSource }),
            },
          ]}
        />
      </Card>
    </>
  );
}

export const ORG_PAYMENT_SECTION = {
  Component: OrgPaymentSection,
  configs: CONFIGS,
  getDefaultFormValues(transaction) {
    return {
      payer: transaction.payer,
    };
  },
  getSubmitData({ sectionFormValues }) {
    return {
      payer: sectionFormValues.payer,
    };
  },
  modifyConfig({ sectionConfig, transaction, user }) {
    const modifiedConfig = { ...sectionConfig };
    if (!isFeatureEnabled(transaction.organization, TRANSACTION_LEVEL_PAYER_CONFIGURATION)) {
      downgradeDisplay(modifiedConfig, "payer", "hidden");
    }
    if (isNotaryNST(user.notaryProfile)) {
      // payment for NST is handled in `nst_payment` section
      downgradeDisplay(modifiedConfig, "payer", "hidden");
    }
    return modifiedConfig;
  },
} satisfies SectionContract<
  OrgPaymentFormValues,
  OrgPaymentSubmitData,
  OrgPayment,
  OrgAccountPayment,
  OrgPaymentUser,
  typeof CONFIGS
>;
