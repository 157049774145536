import EditPayment from "common/transactions/form/sub_forms/payment";
import { TRANSACTION_LEVEL_PAYER_CONFIGURATION } from "constants/feature_gates";
import { isFeatureEnabled } from "util/feature_detection";
import { isNotaryNST } from "common/notary/capacity";

import { type OrgPaymentOrganizationTransaction as Transaction } from "./organization_transaction_fragment.graphql";
import { type OrgPaymentOrganization as Organization } from "./organization_fragment.graphql";
import { type OrgPaymentViewer as Viewer } from "./viewer_fragment.graphql";
import { type Validation } from "../util";

export const validateOrgPayment: Validation<Transaction, Organization, Viewer> = ({
  transaction,
  organization,
  viewer,
  setSubmitErrorModal,
}) => {
  return new Promise((resolve) => {
    const paymentModalEnabled = !isFeatureEnabled(
      transaction.organization,
      TRANSACTION_LEVEL_PAYER_CONFIGURATION,
    );
    if (
      !organization.paymentSpecified &&
      paymentModalEnabled &&
      // NST should never see this validation because:
      // 1. adding payment is part of their onboarding flow
      // 2. we charge them for transactions so they can't change to signer pays
      // 3. they collect $$ from signer through nst_payment flow
      !isNotaryNST(viewer.user!.notaryProfile)
    ) {
      setSubmitErrorModal(
        <EditPayment
          onCancel={() => {
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          onComplete={() => {
            setSubmitErrorModal(null);
            resolve({ status: "passed" });
          }}
        />,
      );
    } else {
      resolve({ status: "passed" });
    }
  });
};
