import { useState, useEffect, useCallback } from "react";

// This hook provides all the necessary attributes—especially ARIA attributes—to be sent to the DOM
// on both the toggle and the content container so the widget is accessible to assistive technologies.
// By default, the hook is “uncontrolled” and will work as is. If the state should be controlled by
// something else the hook will react to the isExpanded value it receives and updates the returned
// props accordingly.

// Borrowed from Kitty Giraudel's hook https://github.com/KittyGiraudel/react-a11y-disclosure
export const useDisclosure = (props: { isExpanded?: boolean; id: string }) => {
  const [isExpanded, setIsExpanded] = useState(
    typeof props.isExpanded !== "undefined" ? props.isExpanded : true,
  );

  useEffect(() => {
    typeof props.isExpanded !== "undefined" && setIsExpanded(props.isExpanded);
  }, [props.isExpanded]);

  const toggle = useCallback(() => setIsExpanded((v) => !v), []);

  const toggleProps = {
    id: `${props.id}-toggle`,
    onClick: toggle,
    type: "button",
    "aria-expanded": isExpanded,
    "aria-controls": `${props.id}-content`,
  };
  const contentProps = {
    id: `${props.id}-content`,
    "aria-hidden": !isExpanded,
    "aria-labelledby": `${props.id}-toggle`,
  };

  return { toggleProps, contentProps, isExpanded, toggle };
};
