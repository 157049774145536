import { FormattedMessage } from "react-intl";

import { PillsHeader, Pill } from "common/core/pill_tabs";
import { OrganizationTransactionContactRoleType } from "graphql_globals";

type Props = {
  fieldName: string;
  onClick: (field: string, arg: string) => void;
  value: string;
};

function CollaboratorRoleSelection({ fieldName, onClick, value }: Props) {
  return (
    <PillsHeader>
      <Pill
        selected={value === OrganizationTransactionContactRoleType.TITLE_AGENT}
        onClick={() => onClick(fieldName, OrganizationTransactionContactRoleType.TITLE_AGENT)}
      >
        <FormattedMessage id="93ace80b-f5d5-4bff-938b-1fcb8710d308" defaultMessage="Title agent" />
      </Pill>
      <Pill
        selected={value === OrganizationTransactionContactRoleType.ESCROW_OFFICER}
        onClick={() => onClick(fieldName, OrganizationTransactionContactRoleType.ESCROW_OFFICER)}
      >
        <FormattedMessage
          id="346ce2e4-7595-47c8-a52b-b0a5a547d925"
          defaultMessage="Escrow officer"
        />
      </Pill>
      <Pill
        selected={value === OrganizationTransactionContactRoleType.ATTORNEY}
        onClick={() => onClick(fieldName, OrganizationTransactionContactRoleType.ATTORNEY)}
      >
        <FormattedMessage id="0b3864fd-8e0b-4b28-b232-23ac0f3ca6b4" defaultMessage="Attorney" />
      </Pill>
    </PillsHeader>
  );
}

export default CollaboratorRoleSelection;
