import { FormattedMessage } from "react-intl";

import ConfirmationModal from "common/core/confirmation_modal";

export enum ModalTypes {
  SIGNER = "SIGNER",
  TITLE = "TITLE",
}

const ModalCopy = {
  [ModalTypes.SIGNER]: {
    description: (
      <FormattedMessage
        id="3d7c3d5e-e0a3-459e-82e1-329bca61eb50"
        defaultMessage="Send to the signer?"
      />
    ),
    action: (
      <FormattedMessage
        id="0f081a46-44aa-4fe6-bc89-790e7579dd7e"
        defaultMessage="Yes, Send to signer"
      />
    ),
  },
  [ModalTypes.TITLE]: {
    description: (
      <FormattedMessage
        id="4c5e4ed3-025f-4fe3-aa0b-d9f3fcfdf823"
        defaultMessage="Send to the title agency?"
      />
    ),
    action: (
      <FormattedMessage
        id="60247e0a-e583-4379-8fed-dd3007484747"
        defaultMessage="Yes, Send to Title Agency"
      />
    ),
  },
};

type Props = {
  onSend: (arg: ModalTypes) => void;
  onCancel: () => void;
  activeSendModalType: ModalTypes;
};

export default ({ activeSendModalType, onSend, onCancel }: Props) => (
  <ConfirmationModal
    onConfirm={() => onSend(activeSendModalType)}
    onCancel={onCancel}
    actionText={ModalCopy[activeSendModalType].action}
    descriptionText={ModalCopy[activeSendModalType].description}
  />
);
