import { OrganizationTransactionVariant, SigningRequirementEnum } from "graphql_globals";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";

import type {
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
  SignerLanding_viewer as Viewer,
} from "../index.query.graphql";

export const LANDING_VARIANTS = {
  BUSINESS_RON: "business-ron",
  REAL_RON: "real-estate-ron",
  NONE: "none",
};

/**
 * @description Get the landing variant when ron-landing-redesign flag is on. New landings should not load for hybrids or anything outside of RONs, so we check for requiresNsaMeeting and transactionVariant here, which is notably not done in v2.
 */

export function getLandingVariant(
  { organizationTransaction, participants, completionRequirements }: DocumentBundle,
  { user }: Viewer,
) {
  const { isMortgage, requiresNsaMeeting, transactionVariant } = organizationTransaction;

  const viewerParticipant = participants!.find((p) => p!.userId === user!.id);
  const isAuthenticatedEsign = viewerParticipant?.proofRequirement?.ca?.selfie;
  const userParticipantIsEsign =
    viewerParticipant?.signingRequirement === SigningRequirementEnum.ESIGN;

  // Proof will have eSign requirement but also proof requirement
  const isNotEsignOrProof =
    !(userParticipantIsEsign || onlyRequiresEsign(completionRequirements)) && !isAuthenticatedEsign;

  if (
    transactionVariant === OrganizationTransactionVariant.NOTARIZATION &&
    requiresNsaMeeting &&
    isNotEsignOrProof
  ) {
    if (isMortgage) {
      return LANDING_VARIANTS.REAL_RON;
    }
    return LANDING_VARIANTS.BUSINESS_RON;
  }
  return LANDING_VARIANTS.NONE;
}
