import "./index.scss";

import PropTypes from "prop-types";

export default function PageModal({ children, sidebar }) {
  return (
    <div className="PageModal">
      {children}
      {sidebar}
    </div>
  );
}

PageModal.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.node,
};

PageModal.defaultProps = {
  sidebar: null,
};
