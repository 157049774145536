import { useEffect, useState, useRef, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Subject, takeUntil, debounceTime, switchMap, startWith, filter, finalize } from "rxjs";
import classNames from "classnames";

import { PageFrameWithPadding } from "common/page_frame";
import Button from "common/core/button";
import { useForm, type UseFormReturn } from "common/core/form";
import { FullLogo } from "common/core/logo";
import { AddressInput, type Address } from "common/core/form/address";
import { Select } from "common/core/form/select";
import LoadingIndicator from "common/core/loading_indicator";
import Link from "common/core/link";
import { segmentTrack } from "util/segment";
import { b, type Intlable } from "util/html";
import { checkAddressFilledOut } from "util/address";
import { useApolloClient } from "util/graphql";
import { MASTER_GENERAL_TERMS_URL } from "constants/globals";
import {
  MARKETING_HOST,
  CONTACT_SALES_URL,
  LENDER_ELIGIBILITY_TITLE_PRICING_URL,
} from "constants/marketing";

import RecordingLocationEligibilitySearch, {
  type RecordingLocationEligibilitySearch as Eligibilities,
} from "./recording_locations.graphql";
import Styles from "./index.module.scss";

export type EligibilitySearchVariables = {
  address: Address;
};

export type AddressFormValues = {
  address: Address;
};

type LocationDropdownFormValues = {
  recordingLocationId: string;
};

type EligibilityData = {
  state: "online" | "hybrid" | null;
  recordingLocations: Eligibilities["viewer"]["eligibilityData"] | undefined;
};

function useEligibilityData(setEligibilityData: (state: EligibilityData) => void) {
  const client = useApolloClient();
  const addressRef = useRef(new Subject<Address>());
  const address$ = addressRef.current;
  const resetRef = useRef(new Subject());
  const reset$ = resetRef.current;
  const unmountedRef = useRef(new Subject());
  const unmounted$ = unmountedRef.current;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset$
      .pipe(
        startWith(null),
        takeUntil(unmounted$),
        switchMap(() => {
          return address$.pipe(
            debounceTime(1000),
            filter((address) => checkAddressFilledOut(address)),
            switchMap((address) => {
              segmentTrack("Eligibility lookup tool address submitted");
              setLoading(true);
              setEligibilityData({ state: null, recordingLocations: [] }); // resets data so form will not preselect location incorrectly
              return client.query<Eligibilities, EligibilitySearchVariables>({
                query: RecordingLocationEligibilitySearch,
                variables: { address },
              });
            }),
            finalize(() => setLoading(false)),
          );
        }),
      )
      .subscribe(({ data }) => {
        setLoading(false);
        const recordingLocations = data.viewer.eligibilityData;
        const firstRecordingLocation = recordingLocations[0];

        const online =
          recordingLocations.length === 1 &&
          Boolean(
            firstRecordingLocation.eligibleUnderwritersExist &&
              firstRecordingLocation.erecordingSupported,
          );
        const hybrid = recordingLocations.length === 1;
        // If online transaction is supported, then hybrid is also supported.
        let supported = null as "online" | "hybrid" | null;
        if (online) {
          supported = "online";
        } else if (hybrid) {
          supported = "hybrid";
        }
        setEligibilityData({ state: supported, recordingLocations });
      });

    return () => {
      unmounted$.next(null);
    };
  }, []);

  return {
    loading,
    reset$,
    address$,
  };
}

// This creates the header above the eligible closing types.
const CLOSING_HEADER = (
  <h2 className={Styles.formHeading}>
    <FormattedMessage id="c20d6b8b-fe06-40f9-8792-ebe890def1a8" defaultMessage="Results" />
  </h2>
);

// This creates the cards for the eligibile closing types
// message is an element to be shown inside the card.
function wrapInClosingCard(message: ReactNode) {
  return (
    <div className={Styles.closingBox}>
      <p className={Styles.closingHeaderBody}>{message}</p>
    </div>
  );
}

// Create the online closing card.
const ONLINE_CLOSING = wrapInClosingCard(
  <FormattedMessage
    id="1b930e96-26c3-4dbe-8f11-3d932adae281"
    defaultMessage="<b>Yes! This property is eligible for an Online Closing.</b> Online Closings can be completed fully on the Notarize platform, including the eNote and Notary meeting."
    values={{ b }}
  />,
);

// Create the hybrid closing card.
const HYBRID_CLOSING = wrapInClosingCard(
  <FormattedMessage
    id="e9dbd3ed-7a28-41cf-ac5b-685b7e97d9a6"
    defaultMessage="<b>This property is also eligible for a Hybrid Closing.</b> You can choose this option for a partial e-sign and partial wet-sign closing."
    values={{ b }}
  />,
);

// Create the hybrid only closing card.
const HYBRID_ONLY_CLOSING = wrapInClosingCard(
  <FormattedMessage
    id="e9dbd3ed-7a28-41cf-ac5b-685b7e97d9a6"
    defaultMessage="<b>This property is not eligible for an Online Closing, but you can perform a Hybrid Closing.</b> Choose the Hybrid Closing option for a partial e-sign and partial wet-sign closing."
    values={{ b }}
  />,
);

// Helper method for when to show the header above the eligibility cards.
const showHeader = (eligibilityData: EligibilityData) => {
  return eligibilityData.state !== null;
};

// Helper method for when to show the online closing card.
const showOnlineClosing = (eligibilityData: EligibilityData) => {
  return eligibilityData.state === "online";
};

// Helper method for when to show the hybrid and online closing card.
const showHybridClosing = (eligibilityData: EligibilityData) => {
  return eligibilityData.state === "online";
};

// Helper method for when to show the hybrid closing card.
const showOnlyHybridClosing = (eligibilityData: EligibilityData) => {
  return eligibilityData.state === "hybrid";
};

const placeholderRecordingLocation = {
  value: "",
  label: (
    <FormattedMessage id="417cdbdf-c634-428b-ae1f-430cacd25f24" defaultMessage="Please choose..." />
  ),
};

// Helper method that converts an array of eligibilityData into
// [{label, value}, ...] which a select can take in directly.
const formatRecordingLocations = (recordingLocations: EligibilityData["recordingLocations"]) => {
  if (!recordingLocations) {
    return [placeholderRecordingLocation];
  }

  // Add in a placeholder in case to prevent auto-select when there is more than 1 location
  const formattedRecordingLocations: { value: string; label: Intlable }[] =
    recordingLocations.length > 1 ? [placeholderRecordingLocation] : [];
  recordingLocations.forEach((location) => {
    formattedRecordingLocations.push({ value: location.id, label: location.name });
  });
  return formattedRecordingLocations;
};

// This is the main module that renders the page.
function Eligibility() {
  useEffect(() => {
    segmentTrack("Eligibility lookup tool page loaded");
  }, []);
  // This creates the state that holds the current eligibility types that should
  // be shown as well as the the array of recording locations, so that they can
  // be selected between. We also can set the initial values here.
  const [eligibilityData, setEligibilityData] = useState<EligibilityData>({
    state: null,
    recordingLocations: undefined,
  });

  // This form only takes in the address fields and makes the graphql query to see
  // which recording locations are relevant.
  const form = useForm<AddressFormValues>({
    mode: "onChange",
    shouldFocusError: false,
    defaultValues: {
      address: {
        country: "US",
      },
    },
  });

  const values = form.watch();
  const { loading, reset$, address$ } = useEligibilityData(setEligibilityData);

  // This is a helper method that will reset all the values in the form and
  // reset the state which will remove the eligibility cards.
  const formReset = (form: UseFormReturn<AddressFormValues>) => {
    form.reset();
    setEligibilityData({ state: null, recordingLocations: undefined });
    reset$.next(null);
  };

  // This creates the form for the dropdown of recording locations.
  // This allows the user to see the difference in eligibilities for each recording jurisdiction.
  // Also having this as a separate form means that we don't do an extra graphql query
  const recordingLocationForm = useForm<LocationDropdownFormValues>({
    mode: "onTouched",
    shouldFocusError: false,
  });

  // This is the callback method when the recording location is selected.
  const onRecordingLocationSubmit = ({ recordingLocationId }: LocationDropdownFormValues) => {
    const recordingLocations = eligibilityData.recordingLocations;
    const recordingLocation = (recordingLocations || []).find(
      (location) => location.id === recordingLocationId,
    );
    if (recordingLocation) {
      if (recordingLocation.eligibleUnderwritersExist && recordingLocation.erecordingSupported) {
        setEligibilityData({ ...eligibilityData, state: "online" });
      } else {
        setEligibilityData({ ...eligibilityData, state: "hybrid" });
      }
    } else {
      // This is an error...  It means the id selected is not in the list.
      setEligibilityData({ ...eligibilityData, state: null });
    }
    reset$.next(null);
  };

  // This will render the button to create transaction after the eligibility search
  const showCreateButton = (className: string) => {
    if (showHeader(eligibilityData)) {
      return (
        <Link to="/transaction/setup" className={className} underlined={false}>
          <FormattedMessage
            id="1b930e96-26c3-4dbe-8f11-3d932adae281"
            defaultMessage="Upload your document package now"
          />
        </Link>
      );
    }
  };

  return (
    <PageFrameWithPadding>
      <Link to={"/login"} className={Styles.rightButton}>
        <FormattedMessage id="07a9f39c-d612-462f-ad22-7b77e4561e8b" defaultMessage="Sign in" />
      </Link>
      <div className={Styles.container}>
        <div className={Styles.formHeader}>
          <FullLogo className={Styles.logoImg} />
          <h1 className={Styles.heading}>
            <FormattedMessage
              id="c20d6b8b-fe06-40f9-8792-ebe890def1a8"
              defaultMessage="Is your closing eligible for remote online notarization?"
            />
          </h1>
          <p className={Styles.paragraph}>
            <FormattedMessage
              id="cf735141-ae04-42d1-9d5a-ed135c46e15c"
              defaultMessage={
                "{site} powers more than 7,000 businesses across hundreds of industries, as well as the fastest-growing lenders and title companies to help streamline operations while delivering a world-class customer experience."
              }
              values={{
                site: (
                  <a href={MARKETING_HOST} target="_blank" rel="noreferrer">
                    Proof
                  </a>
                ),
              }}
            />
          </p>
        </div>

        <div className={Styles.formContainer}>
          <div className={Styles.formSection}>
            <div className={Styles.box}>
              <h2 className={Styles.formHeading}>
                <FormattedMessage
                  id="23ff3a7c-23c3-4918-9e4d-15c0328d3baa"
                  defaultMessage="Determine eligibile closing types in two steps"
                />
              </h2>
              <div className={Styles.inputContainer}>
                <h3 className={Styles.inputLabel}>
                  <FormattedMessage
                    id="7f18a6e2-2d88-409a-b84f-f9574a072a6c"
                    defaultMessage="1. Enter the property address"
                  />
                </h3>
                <form
                  name="RecordingLocationForm"
                  onChange={() => {
                    address$.next(values.address);
                  }}
                >
                  <AddressInput<"address", AddressFormValues> form={form} name="address" required />
                </form>
              </div>
              <div className={Styles.inputContainer}>
                <h3 className={Styles.inputLabel}>
                  <FormattedMessage
                    id="bd55ad8f-6c4f-4d7c-a519-608aa566073c"
                    defaultMessage="2. Review recording location"
                  />
                </h3>
                <form
                  name="LocationSelectorForm"
                  onChange={recordingLocationForm.handleSubmit(onRecordingLocationSubmit)}
                >
                  <Select
                    data-automation-id="recording-location-select-field"
                    aria-invalid={false}
                    disabled={
                      !eligibilityData.recordingLocations ||
                      eligibilityData.recordingLocations.length < 2
                    }
                    items={formatRecordingLocations(eligibilityData.recordingLocations)}
                    {...recordingLocationForm.register("recordingLocationId", {
                      onChange: (e) => {
                        if (e.target.value === placeholderRecordingLocation.value) {
                          setEligibilityData({ ...eligibilityData, state: null });
                        }
                      },
                    })}
                  />
                </form>
              </div>
              {/* This is where we conditionally render all of the cards. */}
              {showHeader(eligibilityData) && CLOSING_HEADER}
              {showOnlineClosing(eligibilityData) && (
                <div className={Styles.onlineClosingBoxContainer}>{ONLINE_CLOSING}</div>
              )}
              <div>{showHybridClosing(eligibilityData) && HYBRID_CLOSING}</div>
              <div>{showOnlyHybridClosing(eligibilityData) && HYBRID_ONLY_CLOSING}</div>
              {showCreateButton(
                showHybridClosing(eligibilityData) ? Styles.onlineLink : Styles.hybridOnlyLink,
              )}
              {loading && <LoadingIndicator />}

              <Button
                variant="tertiary"
                buttonColor="action"
                onClick={() => {
                  formReset(form);
                }}
                withIcon={{ name: "rotate-clockwise", placement: "left" }}
                className={Styles.resetButton}
              >
                <FormattedMessage
                  id="6b42757b-bfd6-49fe-9f9d-e21e6b529678"
                  defaultMessage="Start over"
                />
              </Button>
            </div>
            <p className={Styles.disclaimer}>
              <FormattedMessage
                id="c330efcd-ecdc-46ff-b02f-32181609c94b"
                defaultMessage={
                  "The information provided by and through use of this page does not constitute, and is not intended to be, " +
                  "legal advice. All information contained herein is made available for informational purposes only and does not guarantee " +
                  "loan eligibility. By using or accessing this tool, whether as an individual or entity, the User accepts and agrees to be bound by the {touLink}."
                }
                values={{
                  touLink: (
                    <Link href={MASTER_GENERAL_TERMS_URL}>
                      <FormattedMessage
                        id="ac95428f-b26f-4ecb-838a-c96ccbcc4133"
                        defaultMessage="General Terms"
                      />
                    </Link>
                  ),
                }}
              />
            </p>
          </div>

          <div className={Styles.ctaSection}>
            <div className={classNames(Styles.box, Styles.ctaBox)}>
              <h2 className={Styles.sideContainerHeading}>
                <FormattedMessage
                  id="e6087ec2-365f-483b-8ab3-25db8c246557"
                  defaultMessage="Ready to get started?"
                />
              </h2>
              <div className={Styles.sideContainerSection}>
                <h3 className={Styles.sideContainerSubheading}>
                  <FormattedMessage
                    id="273696bf-5513-4fbf-a22b-5dd824a57ab4"
                    defaultMessage="For Title Agencies"
                  />
                </h3>
                <p className={Styles.sideContainerText}>
                  <FormattedMessage
                    id="225b0acc-0f98-4229-bcb3-67745e18d6c7"
                    defaultMessage="$99/closing session. No platform fees. No commitment required."
                  />
                </p>
                <Link
                  className={Styles.solidButton}
                  href={LENDER_ELIGIBILITY_TITLE_PRICING_URL}
                  underlined={false}
                >
                  <FormattedMessage
                    id="c69be33d-2d6c-4c7f-a0a4-71d95d2db736"
                    defaultMessage="Learn more"
                  />
                </Link>
              </div>
              <div className={Styles.sideContainerSection}>
                <h3 className={Styles.sideContainerSubheading}>
                  <FormattedMessage
                    id="273696bf-5513-4fbf-a22b-5dd824a57ab4"
                    defaultMessage="For lenders, loan officers, & real estate agents"
                  />
                </h3>
                <Link
                  className={Styles.hollowButton}
                  href={`${CONTACT_SALES_URL}?ref=lendereligibility`}
                  underlined={false}
                >
                  <FormattedMessage
                    id="e4329a09-d5a2-4205-ad1d-8205d62d1a9f"
                    defaultMessage="Contact Sales"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageFrameWithPadding>
  );
}

export default Eligibility;
