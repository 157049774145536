import { useState, type ReactNode } from "react";
import classnames from "classnames";

import Icon from "common/core/icon";
import { Substyle } from "common/core/typography";

import Styles from "./item.module.scss";

type Props = {
  header: ReactNode;
  subheader?: ReactNode;
  centerHeader?: ReactNode;
  initialDisplay?: boolean;
  hideToggle?: boolean;
  children?: ReactNode;
  isResponsive?: boolean;
  showPointerCursor?: boolean;
};

function CollapsibleListItem({
  header,
  subheader,
  centerHeader,
  initialDisplay,
  hideToggle,
  isResponsive,
  children,
  showPointerCursor,
}: Props) {
  const [isOpen, setIsOpen] = useState(Boolean(initialDisplay));
  const forceHideToggle = !children || hideToggle;

  const iconCx = classnames(Styles.icon, forceHideToggle && Styles.hiddenIcon);
  const iconName = `caret-${isOpen ? "up" : "down"}`;
  const headerCx = classnames(Styles.header, {
    [Styles.emptyHeader]: forceHideToggle && !showPointerCursor,
    [Styles.headerExpanded]: isOpen,
  });
  const contentCx = classnames(Styles.content, isOpen && Styles.contentExpanded);
  return (
    <li className={classnames(Styles.item, isResponsive && Styles.responsive)}>
      <div className={headerCx} onClick={() => !forceHideToggle && setIsOpen((o) => !o)}>
        <span className={Styles.headerInner}>
          <Icon className={iconCx} name={iconName} />
          <Substyle textStyle="subtitle">{header}</Substyle>
        </span>
        {centerHeader}
        <span className={Styles.subHeaderInner}>{subheader}</span>
      </div>
      <div className={contentCx}>{isOpen && children}</div>
    </li>
  );
}

export default CollapsibleListItem;
