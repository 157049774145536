import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import { ENABLE_ENOTES_IN_HYBRIDS } from "constants/feature_gates";
import { useFeatureFlag } from "common/feature_gating";

import ConvertToHybridMutation, {
  type ConvertToHybrid_convertOrganizationTransactionToHybrid_organizationTransaction_organizationTransactionWitnesses,
} from "./convert_to_hybrid_mutation.graphql";
import Styles from "./convert_to_hybrid_confirmation_modal.module.scss";

const ERRORS = (error: string) => {
  switch (error) {
    case "not_found":
      return "notFound";
    case "cannot_convert_to_hybrid":
      return "cannotConvertToHybrid";
    case "unprocessable_entity":
      return "unprocessableEntity";
    default:
      return "genericError";
  }
};

const MESSAGES = defineMessages({
  notFound: {
    id: "4bbd84be-fa7b-4b3b-9f6a-6b8674d43658",
    defaultMessage: "Error: The transaction was deleted or you no longer have access to it.",
  },
  cannotConvertToHybrid: {
    id: "e583a547-ec47-4128-bf4b-6d0ca7465550",
    defaultMessage:
      "Error: You cannot convert this transaction to a hybrid because you cannot edit it.",
  },
  unprocessableEntity: {
    id: "9af8e7ad-e1f8-4e6d-b79a-4fdcc79fcd47",
    defaultMessage: "Error: Failed to convert to hybrid.",
  },
  genericError: {
    id: "603c2d42-ff97-451e-9cdb-8069ea6f4207",
    defaultMessage: "Error: Something went wrong. Please contact support.",
  },
  info: {
    id: "0448eb7b-68c2-4d6d-808c-73bb32d32389",
    defaultMessage: "All notary meeting documents will be changed to wet sign documents.",
  },
  enoteInfo: {
    id: "ba3ec275-939e-4035-a06b-a67c4caa8f2c",
    defaultMessage: "If there is an eNote present, it will be deleted.",
  },
});

type Props = {
  transactionId: string;
  onConvert: (
    transactionType: string,
    witnesses: ConvertToHybrid_convertOrganizationTransactionToHybrid_organizationTransaction_organizationTransactionWitnesses[],
  ) => void;
  onCancel: () => void;
};

function ConvertToHybridConfirmationModal({ transactionId, onConvert, onCancel }: Props) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const convertToHybridMutation = useMutation(ConvertToHybridMutation);
  const showEnoteInfo = !useFeatureFlag(ENABLE_ENOTES_IN_HYBRIDS);
  const onSubmit = () => {
    setIsLoading(true);
    convertToHybridMutation({
      variables: {
        input: { organizationTransactionId: transactionId },
      },
    })
      .then(({ data }) => {
        setIsLoading(false);
        const transaction = data!.convertOrganizationTransactionToHybrid!.organizationTransaction;
        onConvert(
          transaction.transactionType as string,
          transaction.organizationTransactionWitnesses,
        );
      })
      .catch((error?: Error) => {
        setIsLoading(false);
        if (!isGraphQLError(error)) {
          captureException(error);
        }
        const err = isGraphQLError(error) ? error.graphQLErrors[0] : error;
        setErrorMessage(err?.message || "error");
      });
  };

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="63057e4b-94d7-42d9-979e-d9dd7366686f"
          defaultMessage="Convert transaction type to hybrid?"
        />
      }
      footerSeparator={false}
      buttons={[
        <Button onClick={onCancel} buttonColor="dark" variant="tertiary" key="cancel">
          <FormattedMessage id="5d4d207e-355e-459d-acee-66e5b54e9cbc" defaultMessage="Cancel" />
        </Button>,
        <Button
          automationId="confirm-convert-to-hybrid"
          onClick={onSubmit}
          buttonColor="action"
          variant="primary"
          key="confirm"
          isLoading={isLoading}
        >
          <FormattedMessage
            id="2f36ef51-2e67-4499-ba1f-acd4f0fdbc51"
            defaultMessage="Yes, Convert"
          />
        </Button>,
      ]}
    >
      <p>
        {intl.formatMessage(MESSAGES.info)}
        {showEnoteInfo && <> {intl.formatMessage(MESSAGES.enoteInfo)}</>}
      </p>
      <p className={Styles.warning}>
        <Icon name="warning" className={Styles.warningIcon} />
        <FormattedMessage
          id="30d052c6-cc2f-4a70-b0e4-310e61fd77e8"
          defaultMessage="Warning: This cannot be undone."
        />
      </p>
      {errorMessage && (
        <p className={Styles.error}>{intl.formatMessage(MESSAGES[ERRORS(errorMessage)])}</p>
      )}
    </WorkflowModal>
  );
}

export default ConvertToHybridConfirmationModal;
