import { useState, useMemo } from "react";

import Styles from "./index.module.scss";
import { PDFWrapper, type PageInfo } from "..";
import { PDFBase, PageNavigationToggleButton } from "../util";
import { ZoomInButton, ZoomOutButton, FitToWidthButton } from "../zoom";
import { PageNumber } from "../page_number";

type Props = {
  url: string;
  className?: string;
  hidePageInfo?: boolean;
  hideZoomButtons?: boolean;
};

function useStablePDFURL(url: string): string {
  // The presumption is that we only ever use urls where the origin and the
  // path matter. For s3 URLs, this makes sense since the search params
  // change all the time, but shouldn't cause a re-download.
  const { origin, pathname } = new window.URL(url);
  const stableUrl = `${origin}/${pathname}`;
  return useMemo(() => url, [stableUrl]);
}

/** A quick and dirty read-only pdf viewer */
export function PDFViewer({ url, className }: Props) {
  const stablePdfUrl = useStablePDFURL(url);
  return (
    <PDFWrapper>
      <PDFBase stableMainPdfUrl={stablePdfUrl} className={className} readOnly />
    </PDFWrapper>
  );
}

export function PDFViewerWithControls({ url, className, hidePageInfo, hideZoomButtons }: Props) {
  const stablePdfUrl = useStablePDFURL(url);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  return (
    <PDFWrapper>
      <div className={Styles.pageNumberContainer}>
        {!hidePageInfo && pageInfo !== null && (
          <PageNumber index={pageInfo.pageIndex} totalCount={pageInfo.totalPages} />
        )}
      </div>
      <nav className={Styles.zoomControlContainer}>
        <PageNavigationToggleButton />
        {!hideZoomButtons && (
          <>
            <ZoomInButton />
            <ZoomOutButton />
          </>
        )}
        <FitToWidthButton />
      </nav>
      <PDFBase
        stableMainPdfUrl={stablePdfUrl}
        className={className}
        onPageChange={setPageInfo}
        readOnly
      />
    </PDFWrapper>
  );
}
