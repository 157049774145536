import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import { Column, Container, Row } from "common/core/responsive";
import {
  BackButton,
  PageTitle,
  StartOverButton,
  Step1Header,
  TrackNewSetupPageAnalyticsContext,
  useNewSetupPageAnalytics,
} from "common/mortgage/transactions/setup";
import ClosingTypeCard, {
  type ClosingType,
  getMutationParamFromClosingType,
  supportsHybrid,
  useClosingType,
} from "common/mortgage/transactions/setup/closing_type";
import PropertyAddressCard, {
  usePropertyAddressInput,
} from "common/mortgage/transactions/setup/property_address";
import CollaboratorCard, {
  useCollaboratorSearchingType,
} from "common/mortgage/transactions/setup/collaborator";
import TitleUnderwriterCard, {
  useTitleUnderWriterOption,
} from "common/mortgage/transactions/setup/title_underwriter";
import SaveAndCreateTransaction, { Blurb } from "common/mortgage/transactions/setup/save";
import { useActiveOrganization } from "common/account/active_organization";
import { locationRecordable } from "common/mortgage/transactions/utils";
import { useMutation } from "util/graphql";
import { transactionEditRoute } from "util/routes";
import { b } from "util/html";
import { PAPER_RECORDING_BLOG_POST } from "constants/marketing";
import {
  LOAN_MOD_BORROWER,
  NON_ONBOARDED_TITLE_ENABLED,
  REAL_ESTATE_COLLAB_ENABLED,
} from "constants/feature_gates";
import {
  MortgageTransactionType,
  OrganizationTransactionContactRoleType,
  RecordingTypes,
} from "graphql_globals";
import { TitleLookupErrors } from "common/mortgage/transactions/edit/title_agency_service";
import { useOrgFlag } from "common/org_flag_gating";
import LoadingIndicator from "common/core/loading_indicator";
import { Heading } from "common/core/typography";
import { useFeatureFlag } from "common/feature_gating";
import { getRequirements } from "common/mortgage/transactions/requirements_service/service";
import { MORTGAGE_TRANSACTION_REQUIREMENTS } from "constants/transaction";
import { isDisabled } from "util/requirements";

import CreateOrganizationTransactionV2 from "./create_organization_transaction.mutation.graphql";
import Styles from "./index.module.scss";

type InteractionCollection = {
  closingTypeInteraction: ReturnType<typeof useClosingType>;
  propertyAddressInteraction: ReturnType<typeof usePropertyAddressInput>;
  collaboratorSearchingTypeInteraction: ReturnType<typeof useCollaboratorSearchingType>;
  titleUnderwriterOptionInteraction: ReturnType<typeof useTitleUnderWriterOption>;
};

function fulfillsCollaboratorRequirement(
  collaboratorSearchingTypeInteraction: ReturnType<typeof useCollaboratorSearchingType>,
) {
  const { eligibleTitleUnderwriters } = collaboratorSearchingTypeInteraction;

  return eligibleTitleUnderwriters.some(
    (underwriter) => underwriter.compatibleWithAvailableNotaries,
  );
}

function fulfillsTitleUnderwriterRequirement(
  titleUnderwriterOptionInteraction: ReturnType<typeof useTitleUnderWriterOption>,
  realEstateCollabEnabled: boolean,
) {
  const { titleUnderwriter, option } = titleUnderwriterOptionInteraction;

  return titleUnderwriter || (option === "no" && realEstateCollabEnabled);
}

function calculateHybridOption({
  closingTypeInteraction,
  propertyAddressInteraction,
}: InteractionCollection) {
  const { closingType } = closingTypeInteraction;
  const { propertyAddress, formErrors } = propertyAddressInteraction;

  const emptyDisabledState = {
    buttonHint: (
      <FormattedMessage
        id="a75fb0ee-3946-4636-bbd2-872b97e6c981"
        defaultMessage="Closing type and property address are needed in order to continue with a hybrid closing."
      />
    ),
  };

  if (!closingType) {
    return emptyDisabledState;
  }

  if (closingType === MortgageTransactionType.heloc) {
    return {
      blurb: (
        <Blurb type="info">
          <FormattedMessage
            id="8ecba972-9ee4-4ac2-8db5-19d2adc0441d"
            defaultMessage="<b>HELOCs cannot be completed as hybrid closings on Proof.</b>"
            values={{ b }}
          />
        </Blurb>
      ),
    };
  } else if (closingType === MortgageTransactionType.loan_mod_borrower) {
    return {
      blurb: (
        <Blurb type="info">
          <FormattedMessage
            id="6302aed4-5d1c-4d41-b0a4-c711d7f8c393"
            defaultMessage="<b>Loan modifications cannot be completed as hybrid closings on Proof.</b>"
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  if (propertyAddress && !formErrors) {
    return {
      blurb: (
        <Blurb type="info">
          <FormattedMessage
            id="11600946-e866-4801-9402-537882aa2bcb"
            defaultMessage="<b>This property is eligible for a hybrid closing.</b> Choose this option for a partial eSign and partial wet sign closing."
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  return emptyDisabledState;
}

function calculateEcloseOption(
  {
    closingTypeInteraction,
    propertyAddressInteraction,
    collaboratorSearchingTypeInteraction,
    titleUnderwriterOptionInteraction,
  }: InteractionCollection,
  realEstateCollabEnabled: boolean,
) {
  const { closingType } = closingTypeInteraction;
  const { propertyAddress, selectedRecordingLocation, availableRecordingLocations, formErrors } =
    propertyAddressInteraction;
  const {
    titleAgency,
    eligibleTitleUnderwriters,
    loading: collaboratorLoading,
    hasCollaboratorInformation,
  } = collaboratorSearchingTypeInteraction;
  const emptyDisabledState = {
    buttonHint: (
      <FormattedMessage
        id="d70203ac-f147-4b51-99a3-42e6f8cfd903"
        defaultMessage="eClosing eligibility must be verified in order to continue with an eClosing."
      />
    ),
  };

  if (!closingType || formErrors) {
    return emptyDisabledState;
  }

  const nonHybridTransaction = !supportsHybrid(closingType);
  if (propertyAddress && availableRecordingLocations.length === 0) {
    return {
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="e02b1bdf-fc20-41e2-b44c-269ea02937ee"
            defaultMessage="<b>We were unable to find a recording location for this property.</b> This closing cannot be completed online. {nonHybridTransaction, select, true{} other{Select hybrid to proceed.}}"
            values={{ b, nonHybridTransaction }}
          />
        </Blurb>
      ),
    };
  }

  if (selectedRecordingLocation && !selectedRecordingLocation.usState.ronSupported) {
    return {
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="47829ef5-99b5-4575-8854-38630a016bae"
            defaultMessage="<b>Proof does not currently support full eClosings for property in this state. {nonHybridTransaction, select, true{} other{Select hybrid to proceed.}}</b>"
            values={{ b, nonHybridTransaction }}
          />
        </Blurb>
      ),
    };
  }

  if (
    selectedRecordingLocation &&
    !selectedRecordingLocation.usState.supportedTransactionTypes?.includes(closingType)
  ) {
    return {
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="4cd27b78-b160-40f3-a258-f20fa645f81f"
            defaultMessage="<b>This state does not allow the selected closing type to be conducted using RON.</b>"
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  if (
    selectedRecordingLocation &&
    !selectedRecordingLocation.erecordingSupported &&
    selectedRecordingLocation.recordingType === RecordingTypes.NONE
  ) {
    return {
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="4c30e9d9-0210-464b-b6c6-f5a7b7cedaf1"
            defaultMessage="<b>This property's recording location (county) does not eRecord.</b> This closing cannot be completed online. {nonHybridTransaction, select, true{} other{Select hybrid to proceed.}}"
            values={{ b, nonHybridTransaction }}
          />
        </Blurb>
      ),
    };
  }

  if (
    titleAgency &&
    titleAgency.id !== "" &&
    !eligibleTitleUnderwriters.length &&
    !collaboratorLoading
  ) {
    return {
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="280e3372-825d-4dc4-bac5-4ee66e10ea91"
            defaultMessage="<b>We were unable to find an underwriter</b> that works with this business, in this state, and whose notary requirements are serviceable on Proof."
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  if (
    eligibleTitleUnderwriters.length &&
    !eligibleTitleUnderwriters.some((underwriter) => underwriter.compatibleWithAvailableNotaries)
  ) {
    return {
      blurb: (
        <Blurb type="error">
          <FormattedMessage
            id="f6bb08b1-65b8-4b8f-b741-f55c1617a673"
            defaultMessage="<b>There are no eligible notaries for this closing.</b> This property is eligible for an eClose, but no notaries can service it. Select hybrid to proceed."
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  const generalEcloseRequirements = Boolean(
    hasCollaboratorInformation &&
      fulfillsTitleUnderwriterRequirement(
        titleUnderwriterOptionInteraction,
        realEstateCollabEnabled,
      ),
  );
  if (
    selectedRecordingLocation?.recordingType === RecordingTypes.PAPER &&
    generalEcloseRequirements
  ) {
    return {
      blurb: (
        <Blurb type="info">
          <FormattedMessage
            id="5df86036-cd33-4afd-9c2a-25c5908181c1"
            defaultMessage="<b>This property is eligible for an eClosing, but you need to paper out due to eRecording availability.</b> <link>Learn more</link> about paper out laws."
            values={{
              b,
              link: (text) => <Link href={PAPER_RECORDING_BLOG_POST}>{text}</Link>,
            }}
          />
        </Blurb>
      ),
    };
  }

  if (
    selectedRecordingLocation?.recordingType === RecordingTypes.ELECTRONIC &&
    (nonHybridTransaction || generalEcloseRequirements)
  ) {
    return {
      blurb: (
        <Blurb type="success">
          <FormattedMessage
            id="a75cb78b-8ff1-4930-b1fc-901b81a60c71"
            defaultMessage="<b>This property is eligible for an eClosing.</b> Complete this closing fully on the Proof platform, including the eNote and notary meeting."
            values={{ b }}
          />
        </Blurb>
      ),
    };
  }

  return emptyDisabledState;
}

function useSetupTransactionExecution(
  props: InteractionCollection & { organizationId: string; realEstateCollabEnabled: boolean },
) {
  const {
    organizationId,
    closingTypeInteraction: { closingType },
    propertyAddressInteraction: {
      propertyAddress,
      fetching: propertyAddressLoading,
      selectedRecordingLocation,
    },
    collaboratorSearchingTypeInteraction,
    titleUnderwriterOptionInteraction,
    realEstateCollabEnabled,
  } = props;
  const {
    loading: collaboratorLoading,
    titleAgency,
    titleAgentLookup,
    collaboratorEmail,
    collaboratorName,
    hasCollaboratorInformation,
  } = collaboratorSearchingTypeInteraction;
  const { titleUnderwriter } = titleUnderwriterOptionInteraction;
  const [creatingHybridTransaction, setCreatingHybridTransaction] = useState(false);
  const [creatingEcloseTransaction, setCreatingEcloseTransaction] = useState(false);
  const createTransaction = useMutation(CreateOrganizationTransactionV2);
  const navigate = useNavigate();
  const { track } = useNewSetupPageAnalytics();

  const formLoading = propertyAddressLoading || collaboratorLoading;
  const isBaseRealEstateTransactionType =
    closingType &&
    isDisabled(
      getRequirements(closingType, "lender")[MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER],
    );
  const canCreateBaseEclose =
    isBaseRealEstateTransactionType &&
    locationRecordable(selectedRecordingLocation) &&
    selectedRecordingLocation?.usState.supportedTransactionTypes?.includes(closingType);
  const extendedEcloseRequirements = Boolean(
    hasCollaboratorInformation &&
      fulfillsTitleUnderwriterRequirement(
        titleUnderwriterOptionInteraction,
        realEstateCollabEnabled,
      ),
  );
  const eligibleEcloseTransaction = canCreateBaseEclose || extendedEcloseRequirements;

  const canCreateHybridTransaction = Boolean(
    !creatingEcloseTransaction && !formLoading && propertyAddress && supportsHybrid(closingType!),
  );
  const canCreateEcloseTransaction =
    !creatingHybridTransaction && !formLoading && eligibleEcloseTransaction;

  const createNewTransaction = (hybrid: boolean) => {
    return async () => {
      track("continue-button", {
        orgType: "lender",
        type: hybrid ? "hybrid" : "eclose",
        recordingType: selectedRecordingLocation?.recordingType,
      });
      if (hybrid) {
        setCreatingHybridTransaction(true);
      } else {
        setCreatingEcloseTransaction(true);
      }
      const response = await createTransaction({
        variables: {
          input: {
            organizationId,
            transaction: {
              transactionType: getMutationParamFromClosingType(closingType!, hybrid),
              streetAddress: propertyAddress,
              titleUnderwriterOrgId: titleUnderwriter?.id,
              recordingLocationId: selectedRecordingLocation?.id,
              lenderOrgId: organizationId,
              // "new" lets us know if the organization doesn't exist yet and that we should just pass the name to the next page
              titleAgencyOrgId: titleAgency?.id === "new" ? undefined : titleAgency?.id,
              isMortgage: true,
              paperNoteConsent: hybrid,
            },
            transactionMemberships: {
              titleAgencyId: titleAgency?.id,
            },
            contacts:
              titleAgentLookup &&
              collaboratorName &&
              (!titleAgentLookup.error ||
                titleAgentLookup.error !== TitleLookupErrors.NOT_TITLE_AGENT)
                ? [
                    {
                      email: collaboratorEmail,
                      firstName: collaboratorName.firstName,
                      lastName: collaboratorName.lastName,
                      phoneNumber: titleAgentLookup.phoneNumber,
                      role: OrganizationTransactionContactRoleType.TITLE_AGENT,
                      organizationName: titleAgency?.name,
                      shownToSigner: true,
                      accessToTransaction: true,
                    },
                  ]
                : [],
            customers: [{}],
          },
        },
      });

      const transaction = response.data!.createOrganizationTransactionV2!.organizationTransaction;
      navigate(transactionEditRoute(transaction), { replace: true });
    };
  };

  const closingOptions = {
    hybrid: {
      loading: creatingHybridTransaction,
      onContinue: canCreateHybridTransaction ? createNewTransaction(true) : null,
      ...calculateHybridOption(props),
    },
    eclose: {
      loading: creatingEcloseTransaction,
      onContinue: canCreateEcloseTransaction ? createNewTransaction(false) : null,
      ...calculateEcloseOption(props, realEstateCollabEnabled),
    },
  };

  return {
    closingOptions,
  };
}

export default function SetupTransactionV2() {
  const [lenderOrgId] = useActiveOrganization();
  const closingTypeInteraction = useClosingType();
  const { closingType } = closingTypeInteraction;
  const { orgFlagData, orgFlagLoading } = useOrgFlag(lenderOrgId as string);
  const orgRealEstateCollab = Boolean(orgFlagData[REAL_ESTATE_COLLAB_ENABLED]);
  const nononboardedEnabled = Boolean(orgFlagData[NON_ONBOARDED_TITLE_ENABLED]);
  const propertyAddressInteraction = usePropertyAddressInput(nononboardedEnabled);

  const { selectedRecordingLocation, formErrors: propertyAddressFormErrors } =
    propertyAddressInteraction;
  const collaboratorSearchingTypeInteraction = useCollaboratorSearchingType({
    lenderOrgId,
    selectedRecordingLocation,
  });
  const {
    loading: collaboratorLoading,
    eligibleTitleUnderwriters,
    titleAgency,
    hasCollaboratorInformation,
  } = collaboratorSearchingTypeInteraction;
  const titleUnderwriterOptionInteraction = useTitleUnderWriterOption({
    titleAgencyId: titleAgency?.id,
  });

  const { closingOptions } = useSetupTransactionExecution({
    organizationId: lenderOrgId!,
    closingTypeInteraction,
    propertyAddressInteraction,
    collaboratorSearchingTypeInteraction,
    titleUnderwriterOptionInteraction,
    realEstateCollabEnabled: Boolean(orgRealEstateCollab),
  });

  const showPropertyAddressCard = Boolean(closingType);
  const showCollaboratorCard =
    closingType !== MortgageTransactionType.heloc &&
    closingType !== MortgageTransactionType.loan_mod_borrower &&
    showPropertyAddressCard &&
    !propertyAddressFormErrors &&
    locationRecordable(selectedRecordingLocation);

  const showUnderwriterCard =
    !collaboratorLoading &&
    showCollaboratorCard &&
    hasCollaboratorInformation &&
    fulfillsCollaboratorRequirement(collaboratorSearchingTypeInteraction);

  useEffect(() => {
    collaboratorSearchingTypeInteraction.clear();
    titleUnderwriterOptionInteraction.clear();
  }, [selectedRecordingLocation]);

  useEffect(() => {
    titleUnderwriterOptionInteraction.clear();
  }, [closingType]);

  const compatibleUnderwriters = useMemo(() => {
    return eligibleTitleUnderwriters.filter(
      ({ compatibleWithAvailableNotaries }) => compatibleWithAvailableNotaries,
    );
  }, [eligibleTitleUnderwriters]);

  const handleClearForm = () => {
    closingTypeInteraction.clear();
    propertyAddressInteraction.clear();
    collaboratorSearchingTypeInteraction.clear();
    titleUnderwriterOptionInteraction.clear();
  };
  // create 2 arrays of allowed closing types, one for with and one for without loan mod
  const deprecatedClosingTypes: ClosingType[] = [
    MortgageTransactionType.heloc,
    MortgageTransactionType.purchase_buyer_loan,
    MortgageTransactionType.refinance,
  ];
  // Should be alphabetized
  const closingTypes: ClosingType[] = [
    MortgageTransactionType.heloc,
    MortgageTransactionType.purchase_buyer_loan,
    MortgageTransactionType.loan_mod_borrower,
    MortgageTransactionType.refinance,
  ];

  const allowedClosingTypes: ClosingType[] = useFeatureFlag(LOAN_MOD_BORROWER)
    ? closingTypes
    : deprecatedClosingTypes;

  if (orgFlagLoading) {
    return <LoadingIndicator />;
  }

  return (
    <TrackNewSetupPageAnalyticsContext orgType="lender">
      <Container>
        <Row className={Styles.headerRow}>
          <Column>
            <BackButton />
          </Column>
        </Row>
        <Row className={Styles.headerRow}>
          <Column>
            <PageTitle />
          </Column>
        </Row>
        <Row>
          <Column xs={12} lg={7} className={Styles.column}>
            <Step1Header />
            <ClosingTypeCard
              allowedClosingTypes={allowedClosingTypes}
              interaction={closingTypeInteraction}
            />
            {showPropertyAddressCard && (
              <PropertyAddressCard
                interaction={propertyAddressInteraction}
                forceLoading={collaboratorLoading && !showCollaboratorCard}
              />
            )}
            {showCollaboratorCard && (
              <div className={Styles.collaboratorCard}>
                <Heading level="h2" textStyle="headingFive" className={Styles.header}>
                  <FormattedMessage
                    id="c5cea24e-7985-40f4-9457-d77d9b3f4a5c"
                    defaultMessage="Finish verifying eClose eligibility"
                  />
                </Heading>
                <CollaboratorCard interaction={collaboratorSearchingTypeInteraction} />
              </div>
            )}
            {showUnderwriterCard && (
              <TitleUnderwriterCard
                lenderOrgId={lenderOrgId}
                interaction={titleUnderwriterOptionInteraction}
                eligibleTitleUnderwriters={compatibleUnderwriters}
                collaborative={Boolean(orgRealEstateCollab)}
              />
            )}
          </Column>
          <Column xs={12} lg={5} xl={4} push={{ xl: 1 }} className={Styles.column}>
            <div>
              <SaveAndCreateTransaction closingOptions={closingOptions} />
              {closingType && <StartOverButton onClick={handleClearForm} />}
            </div>
          </Column>
        </Row>
      </Container>
    </TrackNewSetupPageAnalyticsContext>
  );
}
