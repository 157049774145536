import type {
  OrganizationTransactionDetailedStatus,
  OrganizationTransactionLabels,
} from "graphql_globals";
import {
  TransactionSectionNamespace,
  TransactionSubsectionNamespace,
} from "common/dashboard/filter_dropdown/common";
import { setContainsAllValues } from "common/dashboard/util";

type ExplicitSubTab = TransactionSubsectionNamespace;
type ImplicitSubTab = "draft" | "in_progress" | "ready_to_send";
export type SubTab = ImplicitSubTab | ExplicitSubTab;

export const getSubTab = (
  deserializedArgs: {
    section: TransactionSectionNamespace;
    subSection: TransactionSubsectionNamespace | null;
  },
  draftStatuses: OrganizationTransactionDetailedStatus[],
  readyToSendProgresses: OrganizationTransactionLabels[],
  inProgressStatuses: OrganizationTransactionDetailedStatus[],
  selectedStatuses: Set<OrganizationTransactionDetailedStatus>,
  selectedOrderProgressTypes: Set<OrganizationTransactionLabels>,
  readyToSendTransactionsCount: number,
  currentUserIsClosingOps: boolean,
): SubTab | null => {
  const { section, subSection } = deserializedArgs;
  if (section === TransactionSectionNamespace.ALL) {
    if (
      subSection === TransactionSubsectionNamespace.KBA_ISSUES ||
      subSection === TransactionSubsectionNamespace.CREATED_BY_ME ||
      subSection === TransactionSubsectionNamespace.IDENTITY_RISK
    ) {
      return subSection;
    } else if (setContainsAllValues(selectedStatuses, draftStatuses)) {
      return "draft";
    } else if (setContainsAllValues(selectedStatuses, inProgressStatuses)) {
      return "in_progress";
    }
  } else if (section === TransactionSectionNamespace.OPEN_ORDER) {
    if (
      !currentUserIsClosingOps &&
      readyToSendTransactionsCount > 0 &&
      setContainsAllValues(selectedOrderProgressTypes, readyToSendProgresses)
    ) {
      return "ready_to_send";
    }
  }
  return null;
};
