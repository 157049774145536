import "./index.scss";

import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { CompletionRequirement } from "graphql_globals";
import { Badge } from "common/core/badge";
import CompletionRequirementsText from "util/completion_requirements/completion_requirements_text";

const emphasis = (text: ReactNode) => <span className="SideBar-item--emphasize">{text}</span>;

function SideBar({ verificationOfFactEnabled }: { verificationOfFactEnabled: boolean }) {
  const completionRequirements = verificationOfFactEnabled
    ? CompletionRequirement.PROOFING
    : CompletionRequirement.NOTARIZATION;
  return (
    <div className="SideBar">
      <div className="SideBar-item">
        <Badge kind="new">1</Badge>
        <div>
          <span>
            <FormattedMessage
              id="8d2ae604-91f3-4491-9f06-106082724a5b"
              defaultMessage="<emphasis>Setup</emphasis> your account to get you up and running"
              values={{ emphasis }}
            />
          </span>
        </div>
      </div>
      <div className="SideBar-item">
        <Badge kind="new">2</Badge>
        <div>
          <span>
            <CompletionRequirementsText
              stringId="businessSetupUploadMessage"
              completionRequirements={completionRequirements}
              values={{
                upload: emphasis(
                  <FormattedMessage
                    id="3ab251cd-1c03-4fce-811e-0da56a8d507e"
                    defaultMessage="Upload"
                  />,
                ),
              }}
            />
          </span>
        </div>
      </div>
      <div className="SideBar-item">
        <Badge kind="new">3</Badge>
        <div>
          <span>
            <FormattedMessage
              id="faf2d3a0-013f-4909-a29b-981624b74351"
              defaultMessage="<emphasis>Send</emphasis> your document to the intended signer"
              values={{ emphasis }}
            />
          </span>
        </div>
      </div>
      <div className="SideBar-item">
        <Badge kind="new">4</Badge>
        <div>
          <span>
            <FormattedMessage
              id="7ffe1b08-0fa6-4f53-ac89-84652785ce9a"
              defaultMessage="<emphasis>Track</emphasis> the progress of your signer as they connect with a notary public over live video"
              values={{ emphasis }}
            />
          </span>
        </div>
      </div>
      <div className="SideBar-item">
        <Badge kind="new">5</Badge>
        <div>
          <span>
            <CompletionRequirementsText
              stringId="businessSetupReceiveMessage"
              completionRequirements={completionRequirements}
              values={{
                receive: emphasis(
                  <FormattedMessage
                    id="05a9e52a-25c2-43b6-b3c8-2c8a93f2b50c"
                    defaultMessage="Receive"
                  />,
                ),
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
