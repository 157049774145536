import { useState, useEffect } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import TextField from "common/form/fields/text";
import FormRow from "common/form/elements/row";
import SelectField from "common/form/fields/select";
import FormGroupErrors from "common/form/group_errors";
import AddressSubForm from "common/form/sub_forms/address";
import {
  type TitleAgentLookup,
  TitleLookupErrors,
} from "common/mortgage/transactions/edit/title_agency_service";
import type {
  TitleAgency,
  TitleAgencyOption,
} from "common/mortgage/transactions/edit/title_collaborator/title_agency_autocomplete_service";
import { formattedPropertyAddress } from "util/mortgage/transaction";

import type {
  TransactionEdit_transaction_OrganizationTransaction_titleAgency,
  TransactionEdit_transaction_OrganizationTransaction_pointsOfContact,
  TransactionEdit_organization_Organization,
} from "../transaction_edit_query.graphql";
import Styles from "./index.module.scss";

export type PointOfContact = Pick<
  TransactionEdit_transaction_OrganizationTransaction_pointsOfContact,
  "email" | "organizationName"
> & {
  organizationAddress?: TransactionEdit_transaction_OrganizationTransaction_pointsOfContact["organizationAddress"];
};

const MESSAGES = defineMessages({
  selectOrganizationNamePlaceholder: {
    id: "51932361-f5a2-4402-a1fe-d41d8299580c",
    defaultMessage: "Title agency or business",
  },
  primaryOrganizationNamePlaceholder: {
    id: "51932361-f5a2-4402-a1fe-d41d8299580c",
    defaultMessage: "Title agency or business name",
  },
});

type Props = {
  titleAgentLookup: TitleAgentLookup | null;
  onAddressRemove: () => void;
  initialTitleAgency: TransactionEdit_transaction_OrganizationTransaction_titleAgency | null;
  pointOfContact: PointOfContact;
  isFullRON: boolean;
  onClearAgencyInput: () => void;
  onClearAgencySelect: () => void;
  handleTitleAgencyChange: (id: string | null) => void;
  selectedTitleAgency: TitleAgency | null;
  handleAutocompleteChange: (input: string) => void;
  setAutocompleteInput: (input: string) => void;
  autocompleteLoading: boolean;
  autocompleteOptions: TitleAgency[];
  isSharedInboxEmail?: boolean;
  organization: TransactionEdit_organization_Organization;
};

function useShouldRenderTitleAgencyToggle(
  isFullRON: boolean,
  titleAgentLookupResult: TitleAgentLookup | null,
  showTitleAgencyInput: boolean,
  organization: TransactionEdit_organization_Organization,
) {
  const isNonOnboarded =
    titleAgentLookupResult && titleAgentLookupResult.error === TitleLookupErrors.NOT_FOUND;
  const collabEnabled = Boolean(organization.realEstateCollabEnabled);
  return showTitleAgencyInput || (isFullRON && collabEnabled && isNonOnboarded);
}

function rowRenderer(option: TitleAgencyOption) {
  return (
    <div className={Styles.selectFieldRow}>
      <span className={Styles.selectFieldLabel}>{option.label} </span>
      <span className={Styles.selectFieldAddress}>{formattedPropertyAddress(option.address)}</span>
    </div>
  );
}

function TitleAgencySelection({
  titleAgentLookup,
  onAddressRemove,
  pointOfContact,
  isFullRON,
  initialTitleAgency,
  onClearAgencyInput,
  onClearAgencySelect,
  selectedTitleAgency,
  handleTitleAgencyChange,
  handleAutocompleteChange,
  setAutocompleteInput,
  autocompleteLoading,
  autocompleteOptions,
  isSharedInboxEmail,
  organization,
}: Props) {
  const intl = useIntl();
  const isAddressNonEmpty = Object.values(pointOfContact.organizationAddress || {}).some(Boolean);

  const [showAddress, setShowAddress] = useState(isAddressNonEmpty);
  const [showTitleAgencyInput, setShowTitleAgencyInput] = useState(
    !initialTitleAgency && !!pointOfContact.email && !titleAgentLookup,
  );

  useEffect(() => {
    if (titleAgentLookup && !titleAgentLookup.error && showTitleAgencyInput) {
      setShowTitleAgencyInput(false);
      onClearAgencyInput();
    }
  }, [titleAgentLookup, showTitleAgencyInput]);

  const toggleSelectInputField = (isPrevToggledOn: boolean) => {
    if (isPrevToggledOn) {
      onClearAgencyInput();
    } else {
      onClearAgencySelect();
    }

    return !isPrevToggledOn;
  };

  useEffect(() => {
    if (pointOfContact.organizationName === null) {
      setShowAddress(false);
    }
  }, [pointOfContact.organizationName]);

  return (
    <>
      {!showTitleAgencyInput ? (
        <FormRow>
          <SelectField
            disabled={isSharedInboxEmail}
            data-automation-id="title-agency-field"
            name="titleAgency"
            placeholder={intl.formatMessage(MESSAGES.selectOrganizationNamePlaceholder)}
            useStyledInput
            placeholderAsLabel
            items={autocompleteOptions.map((option) => ({
              value: option.id,
              label: option.name,
              address: option.address,
            }))}
            onInputChange={handleAutocompleteChange}
            noResultsText={autocompleteLoading ? "Loading..." : "No results found"}
            onClose={() => {
              setAutocompleteInput("");
            }}
            optionRenderer={rowRenderer}
            valueRenderer={rowRenderer}
            onOptionChange={(option: TitleAgencyOption | null) => {
              handleTitleAgencyChange(option?.value || null);
            }}
            filterOptions={(options: TitleAgencyOption[]) => {
              // this is to offload responsibility for filtering from react-select to our BE.
              // Typically, we'd rely on ReactSelect's async module, but we need finer control
              // over the options list which Async does not provide.

              if (selectedTitleAgency) {
                return options.filter((option) => option.value !== selectedTitleAgency.id);
              }
              return options;
            }}
          />
          <FormGroupErrors fields={["titleAgency"]} />
        </FormRow>
      ) : (
        <>
          <div className={Styles.nonOnboardedTitleAgencyNameHeader}>
            <h1>
              <FormattedMessage
                id="02bfd192-25f3-4aff-ac31-706fe40f5531"
                defaultMessage="Add title agency or business"
              />
            </h1>
            <a
              className={Styles.nonOnboardedLink}
              onClick={() => setShowTitleAgencyInput(toggleSelectInputField)}
            >
              <FormattedMessage id="4b7e78bd-f463-407e-8b20-125d90070ddf" defaultMessage="Remove" />
            </a>
          </div>
          <TextField
            name={`pointsOfContact[0].organizationName`}
            placeholder={intl.formatMessage(MESSAGES.primaryOrganizationNamePlaceholder)}
            placeholderAsLabel
            useStyledInput
          />
          <FormGroupErrors fields={[`pointsOfContact[0].organizationName`]} />
          {showAddress && (
            <div className={Styles.addressSubForm}>
              <AddressSubForm
                formName="EditTransaction"
                fieldNamePrefix={`pointsOfContact[0].organizationAddress`}
                useStyledInputs
              />
            </div>
          )}
          <FormRow>
            <a
              className={Styles.addAddress}
              onClick={() =>
                setShowAddress((currentValue) => {
                  if (currentValue) {
                    onAddressRemove();
                  }
                  return !currentValue;
                })
              }
            >
              <FormattedMessage
                id="e096d48c-89af-46ba-8478-5f7a017de68e"
                defaultMessage="{showAddress, select, true{- Remove Address} other{+ Add Address}}"
                values={{ showAddress }}
              />
            </a>
          </FormRow>
        </>
      )}
      {useShouldRenderTitleAgencyToggle(
        isFullRON,
        titleAgentLookup,
        showTitleAgencyInput,
        organization,
      ) &&
        !showTitleAgencyInput && (
          <div className={Styles.nonOnboardedDescription}>
            <FormattedMessage
              id="e38cb1ed-24af-4b10-9bda-0a357a06c289"
              defaultMessage="Don't see the title agency or business you're looking for?"
            />
            <a
              className={Styles.nonOnboardedLink}
              onClick={() => setShowTitleAgencyInput(toggleSelectInputField)}
            >
              <FormattedMessage
                id="6a97862f-2fe7-41a0-94c3-cdd28afb8184"
                defaultMessage="Add it manually"
              />
            </a>
          </div>
        )}
    </>
  );
}

export default TitleAgencySelection;
