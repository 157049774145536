import {
  memo,
  useState,
  useRef,
  useEffect,
  cloneElement,
  type ReactNode,
  type ReactElement,
  type KeyboardEvent,
} from "react";
import classnames from "classnames";

import { CircleHoverButton } from "common/core/circle_hover_button/";
import { useEscapeKey, useDropdownNavigation, handleButtonKeyDown } from "util/keyboard_navigation";
import ClickOutside from "common/core/click_outside";
import Icon from "common/core/icon";
import type { Placement } from "common/core/self_manage_placement";

import { MenuWrapper, MenuList, PopoutState } from "./common";
import Styles from "./index.module.scss";

type Target = ReactElement<{
  onClick: () => void;
  onKeyDown: (e: KeyboardEvent) => void;
  "aria-haspopup"?: boolean;
  "aria-expanded"?: boolean;
  children?: ReactNode;
}>;

type Props = {
  children: (controls: { close: () => void }) => ReactNode;
  className?: string;
  placement: Placement;
  automationId?: string;
  onStateChange?: (state: PopoutState) => void;
  /** @deprecated */
  disableDropdownNavigationKeydown?: boolean;
  hasDropdownArrow?: boolean;
  selfManageVerticalAlignment?: boolean;
  verticalAlignmentPadding?: number;
  listRole?: "menu" | "presentation";
  wrapperClassName?: string;
  menuListClassName?: string;
};

type DefaultTargetProps = Props & {
  target?: never;
  "aria-label": string;
};

type CustomTargetProps = Props & {
  target: Target | ((open: boolean) => Target);
  "aria-label"?: never;
};

type PopoutMenuProps = DefaultTargetProps | CustomTargetProps;

function PopoutMenu({
  children,
  target,
  className,
  placement,
  automationId,
  "aria-label": ariaLabel,
  onStateChange,
  disableDropdownNavigationKeydown,
  hasDropdownArrow,
  selfManageVerticalAlignment,
  verticalAlignmentPadding = 0,
  wrapperClassName,
  menuListClassName,
  listRole = "menu",
}: PopoutMenuProps) {
  const [popoutState, setPopoutState] = useState<PopoutState>(PopoutState.CLOSED);
  const isOpen =
    popoutState === PopoutState.OPEN || popoutState === PopoutState.OPENED_WITH_KEYBOARD;
  const targetElement = typeof target === "function" ? target(isOpen) : target;
  const itemsRef = useRef<HTMLDivElement | null>(null);

  const close = () => {
    if (popoutState === PopoutState.CLOSED) {
      return;
    }
    setPopoutState(PopoutState.CLOSED);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    handleButtonKeyDown(
      e,
      () => {
        // default behavior is these keys call onClick, prevents calling twice
        e.preventDefault();
        setPopoutState(PopoutState.OPENED_WITH_KEYBOARD);
      },
      true,
    );
  };
  const toggle = () => {
    isOpen ? setPopoutState(PopoutState.CLOSED) : setPopoutState(PopoutState.OPEN);
  };

  useDropdownNavigation({
    popoutState,
    ref: itemsRef,
    itemSelector: '[role="menuitem"] > *',
  });
  // the "close on Esc" behavior is required for WCAG 2.4.11 please do not disable or remove.
  useEscapeKey(close, isOpen);
  useEffect(() => {
    onStateChange?.(popoutState);
  }, [popoutState, disableDropdownNavigationKeydown]);

  // TODO: DSP-387, replace with shared self manage placement hook
  const [selfAlignBottom, setSelfAlignBottom] = useState(false);
  const [selfAlignmentSet, setSelfAlignmentSet] = useState(false);
  useEffect(() => {
    if (selfManageVerticalAlignment && itemsRef.current && isOpen && !selfAlignmentSet) {
      const isEnoughRoom =
        itemsRef.current.getBoundingClientRect().bottom <
        window.innerHeight - verticalAlignmentPadding;
      setSelfAlignmentSet(true);
      setSelfAlignBottom(!isEnoughRoom);
    }
    if (selfManageVerticalAlignment && !isOpen && selfAlignmentSet) {
      setSelfAlignmentSet(false);
      setSelfAlignBottom(false);
    }
  }, [isOpen]);
  // temp solution until DSP-387 is implemented
  const getSelfPlacementStyles = () => {
    if (placement === "leftTop") {
      return Styles.leftBottom;
    } else if (placement === "bottomLeft") {
      return Styles.topLeft;
    }
  };

  return (
    <ClickOutside onClickOutside={disableDropdownNavigationKeydown ? () => {} : close}>
      {/* Div needed for ClickOutside to work */}
      <div className={wrapperClassName}>
        <MenuWrapper
          isOpen={isOpen}
          className={className}
          automationId={automationId || "popout-menu"}
        >
          {targetElement ? (
            cloneElement(
              targetElement,
              {
                "aria-haspopup": true,
                "aria-expanded": isOpen,
                // Highly unlikely/unrecommended the target does anything other than open/close--note this will overwrite these on target
                onKeyDown: handleKeyDown,
                onClick: toggle,
              },
              <>
                {targetElement.props.children}
                {hasDropdownArrow && <Icon name={isOpen ? "caret-up" : "caret-down"} />}
              </>,
            )
          ) : (
            <CircleHoverButton
              aria-haspopup="true"
              aria-expanded={isOpen}
              onClick={toggle}
              onKeyDown={handleKeyDown}
              aria-label={ariaLabel}
              isSelected={isOpen}
            >
              <Icon name="kebab-menu" size="extraLarge" />
            </CircleHoverButton>
          )}

          {isOpen && (
            <MenuList
              menuClassName={classnames(
                selfAlignmentSet && selfAlignBottom ? getSelfPlacementStyles() : Styles[placement],
                menuListClassName,
              )}
              ref={itemsRef}
              listRole={listRole}
            >
              {children({ close })}
            </MenuList>
          )}
        </MenuWrapper>
      </div>
    </ClickOutside>
  );
}

export default memo(PopoutMenu);
