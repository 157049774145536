import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Button from "common/core/button";
import Icon from "common/core/icon";
import { Heading, Paragraph } from "common/core/typography";
import { asArchive } from "common/details/bundle/save_all";
import InitiateSigning from "common/signer/initiate_signing";
import { MasterPrivacyPolicyUrl, UserTermsOfService } from "common/tos";
import {
  Column,
  Row,
  Container,
  useMobileScreenClass,
  useMatchScreenClass,
} from "common/core/responsive";
import { VerticalStepIndicator } from "common/signer/common";
import { isActive } from "util/transaction";
import { useNavigateToBundleViewRoute } from "util/routes";
import { ALLOWED_PRIMARY_IDS_URL } from "constants/support";
import { OrgTransactionStates } from "graphql_globals";
import Link from "common/core/link";
import { useFeatureFlag } from "common/feature_gating";
import { ORGANIZATION_BRAND_NAME } from "constants/feature_gates";
import { ProofFooter } from "common/proof_footer";
import { waitingForOtherParticipants } from "util/participant";

import LandingContacts from "./contacts";
import LandingSupportInfo from "./support";
import LandingCustomBlock from "./custom_block";
import { LandingWrapper, NoMeetingWarning, WelcomeText } from "./common";
import Styles from "./common.module.scss";
import type {
  SignerLanding_viewer as Viewer,
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
} from "../index.query.graphql";

type WrapperProps = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  readOnly?: boolean;
  isEsign?: boolean;
  handleBlockedAction: (action: () => void) => void;
};

type Props = WrapperProps & {
  initiateSigningForBundle: () => void;
  initiatingSigning: boolean;
};

type ContentProps = Omit<Props, "initiateSigningForBundle" | "handleBlockedAction"> & {
  onContinue: () => void;
  isEsign?: boolean;
};

function NotActive({ documentBundle, onContinue }: ContentProps) {
  return (
    <>
      <Heading level="h2" textStyle="headingFour" className={Styles.subheader}>
        <FormattedMessage
          id="ad713362-20b5-426c-a4a8-ba88cce54eb8"
          defaultMessage="Transaction is inactive"
        />
      </Heading>
      <Paragraph size="large" className={Styles.subheaderInfo}>
        <FormattedMessage
          id="71b984c9-fdff-4b5a-b340-e82b6a394504"
          defaultMessage="{requiresNsaMeeting, select, true{Take some time to read through your document package to ensure an accurate, convenient, and faster closing.} other{Take some time to read through your document package ahead of your closing date to ensure an accurate, convenient, and faster closing.}}"
          values={{
            requiresNsaMeeting: documentBundle.organizationTransaction.requiresNsaMeeting,
          }}
        />
      </Paragraph>
      <Button
        automationId="mortgage-landing-preview-button"
        buttonColor="action"
        buttonSize="large"
        variant="secondary"
        onClick={onContinue}
      >
        <FormattedMessage
          id="84182b28-4419-4d64-ae00-2537633950f0"
          defaultMessage="Preview Documents"
        />
      </Button>
    </>
  );
}

function Expired({ documentBundle, onContinue }: ContentProps) {
  const { requiresNsaMeeting, publicOrganization } = documentBundle.organizationTransaction;
  const organizationName = publicOrganization.name;
  const brandNameEnabled = useFeatureFlag(ORGANIZATION_BRAND_NAME);
  const brandName = brandNameEnabled ? publicOrganization.organizationBrand.name : organizationName;
  return (
    <>
      <Heading level="h2" textStyle="headingFour" className={Styles.subheader}>
        {requiresNsaMeeting ? (
          <FormattedMessage
            id="8224b98d-b0c1-41aa-be6e-69b7b48b08ee"
            defaultMessage="This online closing expired"
          />
        ) : (
          <FormattedMessage
            id="f4656db1-2dc2-40f2-a352-545e3b5b19f3"
            defaultMessage="The eSign documents expired"
          />
        )}
      </Heading>
      <Paragraph size="large" className={Styles.subheaderInfo}>
        <FormattedMessage
          id="f12ad195-bbb8-4e1c-b80f-84909f1cdf2d"
          defaultMessage="You are unable to take action on these documents as it is past the expiration date. Please contact {organizationName} directly."
          values={{ organizationName: brandName }}
        />
      </Paragraph>
      <Button
        automationId="mortgage-landing-view-button"
        buttonColor="action"
        buttonSize="large"
        variant="secondary"
        onClick={onContinue}
      >
        <FormattedMessage
          id="84182b28-4419-4d64-ae00-2537633950f0"
          defaultMessage="View documents"
        />
      </Button>
    </>
  );
}

function UnableToSign({ onContinue }: ContentProps) {
  return (
    <>
      <Heading level="h2" textStyle="headingFour" className={Styles.subheader}>
        <FormattedMessage
          id="588dfefc-346d-4649-b66d-777e20da39c0"
          defaultMessage="Someone is already signing"
        />
      </Heading>
      <Paragraph size="large" className={Styles.subheaderInfo}>
        <FormattedMessage
          id="1949c30e-2325-4186-a63e-b2254552ce4a"
          defaultMessage="To keep your documents secure, we only allow one person to sign at a time. You can read through the contents of your document or return later to sign."
        />
      </Paragraph>
      <Button
        automationId="mortgage-landing-preview-button"
        buttonColor="action"
        buttonSize="large"
        variant="secondary"
        onClick={onContinue}
      >
        <FormattedMessage
          id="9d008ff8-82a6-42dd-b6f1-1a7cf3c211c6"
          defaultMessage="Preview Documents"
        />
      </Button>
    </>
  );
}

function AwaitingOtherSigners({ onContinue }: ContentProps) {
  return (
    <>
      <Heading level="h2" textStyle="headingFour" className={Styles.subheader}>
        <FormattedMessage
          id="b89c574f-f888-4bf3-853b-2220ac0f0f6c"
          defaultMessage="Awaiting other signers"
        />
      </Heading>
      <Paragraph size="large" className={Styles.subheaderInfo}>
        <FormattedMessage
          id="967ceeb7-47be-48d2-89da-9ec6fd41fc25"
          defaultMessage="You have completed your portion of this closing. Once all signers have fulfilled their requirements, you will receive an email with a link to the final document."
        />
      </Paragraph>
      <Button
        automationId="mortgage-landing-preview-button"
        buttonColor="action"
        buttonSize="large"
        variant="secondary"
        onClick={onContinue}
      >
        <FormattedMessage
          id="29461020-b3b2-41c2-9418-b27ea2c5fd82"
          defaultMessage="Preview Documents"
        />
      </Button>
    </>
  );
}

function Complete({ documentBundle, onContinue, readOnly }: ContentProps) {
  const [downloading, setDownloading] = useState(false);

  return (
    <>
      <Heading level="h2" textStyle="headingFour" className={Styles.subheader}>
        <FormattedMessage
          id="c4b951ee-3faf-4533-9d78-0a1b2b7c4ce8"
          defaultMessage="This is complete"
        />
      </Heading>
      <Button buttonColor="action" buttonSize="large" variant="secondary" onClick={onContinue}>
        <FormattedMessage
          id="3a336f1c-9e40-4b23-bc56-62d49e8fd7a2"
          defaultMessage="View Documents"
        />
      </Button>
      <Button
        automationId={"landing-download-documents"}
        buttonColor="action"
        buttonSize="large"
        variant="secondary"
        onClick={() => {
          if (readOnly) {
            return;
          }
          setDownloading(true);
          asArchive(documentBundle).finally(() => {
            setDownloading(false);
          });
        }}
        isLoading={downloading}
      >
        <FormattedMessage
          id="edcfbb91-8927-4a3f-96f9-bd9527ad17ca"
          defaultMessage="Download Documents"
        />
      </Button>
    </>
  );
}

const getStepsContent = ({
  requiresNsaMeeting,
  totalCount,
}: {
  requiresNsaMeeting: boolean;
  totalCount: number;
}) => {
  if (!requiresNsaMeeting) {
    return (
      <VerticalStepIndicator
        listItems={[
          {
            item: (
              <FormattedMessage
                id="56c3519d-2e57-4cf0-aa40-f3cca598ab92"
                defaultMessage="Electronically sign all eligible documents online."
              />
            ),
            description: (
              <FormattedMessage
                id="500e7e01-3ef5-4092-ba2a-c5a10067b0e0"
                defaultMessage="Sign on any device before your in-person closing."
              />
            ),
          },
          {
            item: (
              <FormattedMessage
                id="483d4a80-2a82-4e21-8685-b7bf366773ee"
                defaultMessage="We'll send a copy of your signed {totalCount, plural, one {document} other {documents}} to the sender."
                values={{ totalCount }}
              />
            ),
            description: (
              <FormattedMessage
                id="045a3289-2f45-4475-ad4d-34ea3455877d"
                defaultMessage="The sender will be notified once you've completed all required fields."
              />
            ),
          },
          {
            item: (
              <FormattedMessage
                id="973526db-8583-4495-ba80-ecd3d5c4a86c"
                defaultMessage="You'll complete the rest of your document signings in person."
              />
            ),
            description: (
              <FormattedMessage
                id="854cea4d-c7ff-4cf9-a446-52834efca0e7"
                defaultMessage="A notary or settlement agent will be at your designated meeting."
              />
            ),
          },
        ]}
      />
    );
  }

  return (
    <VerticalStepIndicator
      listItems={[
        {
          item: (
            <FormattedMessage
              id="91f32e66-3905-4d60-b3d9-876a5013a565"
              defaultMessage="Electronically sign the majority of your documents."
            />
          ),
          description: (
            <FormattedMessage
              id="88f2ebe9-4141-40f1-95d1-b08eb935c14e"
              defaultMessage="Sign on any device before meeting with a remote online notary."
            />
          ),
        },
        {
          item: (
            <FormattedMessage
              id="ee259ef5-b52c-4835-890a-67e0ecc1e076"
              defaultMessage="Verify your identity."
            />
          ),
          description: (
            <FormattedMessage
              id="3b4f086a-98dd-44c4-884f-13e6f3796098"
              defaultMessage="Answer a few personalized questions and provide a valid photo ID."
            />
          ),
        },
        {
          item: (
            <FormattedMessage
              id="6b1a1bf6-e1c5-41d1-afa5-4282ab74be35"
              defaultMessage="Join a live two way video call with a remote online notary."
            />
          ),
          description: (
            <FormattedMessage
              id="e72f594f-9dc6-476a-af46-119c2f1caa7b"
              defaultMessage="Complete all fields. Access, download, and share the signed {totalCount, plural, one {document} other {documents}}."
              values={{ totalCount }}
            />
          ),
        },
      ]}
    />
  );
};

function ReadyToSign({
  documentBundle,
  onContinue,
  initiatingSigning,
  isEsign,
  ctaText,
  isMobile,
  handleBlockedAction,
}: ContentProps & {
  ctaText: ReactNode;
  isMobile: boolean;
  handleBlockedAction: Props["handleBlockedAction"];
}) {
  const isSmall = useMatchScreenClass("xs", "sm");
  const {
    organizationTransaction,
    documents: { totalCount },
  } = documentBundle;
  const { requiresNsaMeeting, publicOrganization } = organizationTransaction;
  const organizationName = publicOrganization.name;
  const brandNameEnabled = useFeatureFlag(ORGANIZATION_BRAND_NAME);
  const brandName = brandNameEnabled ? publicOrganization.organizationBrand.name : organizationName;

  return (
    <>
      <Column xs={12} lg={6} className={isSmall ? Styles.columnSmall : Styles.column}>
        <WelcomeText />
        <Heading level="h2" textStyle="headingFour" className={Styles.subheader}>
          <FormattedMessage
            id="361b08c3-b837-4463-9910-69ed9442b68b"
            defaultMessage="{brandName} has sent you {totalCount, plural, one {a document} other {documents}} to {cta, select, esign {review and sign} notarize {notarize online} other {sign online}}"
            values={{
              brandName,
              totalCount,
              cta: isEsign ? "esign" : requiresNsaMeeting ? "notarize" : "other",
            }}
          />
        </Heading>
        <Paragraph size="large" className={Styles.subheaderInfo}>
          {requiresNsaMeeting ? (
            <FormattedMessage
              id="3da8075a-42c4-462d-87f5-95ee5a64d9d8"
              defaultMessage="Review, sign, notarize, and securely return your document package entirely online."
            />
          ) : (
            <FormattedMessage
              id="d38a6751-eb80-426f-8ca1-c6f170a9c846"
              defaultMessage="Seamlessly review and sign eligible documents in your closing package before your in-person closing meeting."
            />
          )}
        </Paragraph>

        <div className={Styles.features}>
          <Heading level="h3" textStyle="headingSix">
            <FormattedMessage
              id="58bd015f-6a58-4e58-a8dc-469db3289e55"
              defaultMessage="Here's what you'll need:"
            />
          </Heading>
          <ul>
            {requiresNsaMeeting ? (
              <>
                <li className={Styles.feature}>
                  <Icon name="tick" className={Styles.featureIcon} />
                  <Paragraph size="large">
                    <FormattedMessage
                      id="4087d0a9-9ff5-4d7b-a56b-df3ad63e36a9"
                      defaultMessage="A valid photo ID and the last four digits of your SSN. <link>See accepted IDs</link>"
                      values={{
                        link: (msg: ReactNode[]) => (
                          <Link
                            href={ALLOWED_PRIMARY_IDS_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {msg}
                          </Link>
                        ),
                      }}
                    />
                  </Paragraph>
                </li>
                <li className={Styles.feature}>
                  <Icon name="tick" className={Styles.featureIcon} />
                  <Paragraph size="large">
                    <FormattedMessage
                      id="e0200c2c-c0d4-46ce-b5ef-53ea68f18c58"
                      defaultMessage="A smartphone, tablet, or computer with audio and video"
                    />
                  </Paragraph>
                </li>
              </>
            ) : (
              <>
                <li className={Styles.feature}>
                  <Icon name="tick" className={Styles.featureIcon} />
                  <Paragraph size="large">
                    <FormattedMessage
                      id="14576a50-f72e-4094-b110-fc848fd52caa"
                      defaultMessage="Any Windows or Mac Operating System or Apple/Android Mobile Device"
                    />
                  </Paragraph>
                </li>
                <li className={Styles.feature}>
                  <Icon name="tick" className={Styles.featureIcon} />
                  <Paragraph size="large">
                    <FormattedMessage
                      id="75493876-cd64-4a7b-83ca-6406bf1ca970"
                      defaultMessage="Mozilla Firefox, Google Chrome, or Safari browsers"
                    />
                  </Paragraph>
                </li>
              </>
            )}
          </ul>
        </div>
      </Column>
      <Column xs={12} lg={6} className={isSmall ? Styles.columnSmall : Styles.column}>
        <div className={Styles.documentCanvas}>
          <div className={classnames(Styles.documentContainer, Styles.documentContainerNoMargin)}>
            {getStepsContent({ requiresNsaMeeting, totalCount })}
            {!isMobile && (
              <div className={Styles.documentMessage}>
                <Button
                  automationId={"get-started-button"}
                  className={Styles.documentMessageButton}
                  onClick={() => handleBlockedAction(onContinue)}
                  isLoading={initiatingSigning}
                  buttonColor="action"
                  buttonSize="large"
                  variant="primary"
                >
                  {ctaText}
                </Button>
                {!requiresNsaMeeting && <NoMeetingWarning totalCount={totalCount} />}
              </div>
            )}
          </div>
          <div className={classnames(Styles.terms, Styles.termsEsign)}>
            <Paragraph size="small" textColor="subtle" textAlign="center">
              <FormattedMessage
                id="c462acc4-5182-4f49-9a54-a7bcd17e8543"
                defaultMessage="By continuing and clicking ''{ctaText}'', you are agreeing to the Proof {termsOfUse}. For information on our privacy and data use practices please see our {privacyPolicy}."
                values={{
                  termsOfUse: <UserTermsOfService underlined />,
                  privacyPolicy: <MasterPrivacyPolicyUrl underlined />,
                  ctaText,
                }}
              />
            </Paragraph>
          </div>
        </div>
      </Column>
    </>
  );
}

function RealEstateLanding({
  documentBundle,
  viewer,
  initiatingSigning,
  initiateSigningForBundle,
  readOnly,
  isEsign,
  handleBlockedAction,
}: Props) {
  const { user } = viewer;
  const { id, availableForSigning, organizationTransaction, participants, documents } =
    documentBundle;
  const { activation, isExpired, state, publicOrganization, requiresNsaMeeting } =
    organizationTransaction;
  const { organizationBrand } = publicOrganization;
  const isMobile = useMobileScreenClass();

  const navigateToBundleView = useNavigateToBundleViewRoute();
  const isSmall = useMatchScreenClass("xs", "sm");
  // if the transaction is not complete and there is only one signer assume the user can still sign,
  // they may have failed to click the complete button
  const currentUserSigned = waitingForOtherParticipants(
    participants?.find((participant) => participant?.userId === user?.id)?.signingStatus,
    participants,
  );

  const readyToSign =
    isActive(activation) &&
    !isExpired &&
    state !== OrgTransactionStates.COMPLETED &&
    availableForSigning &&
    !currentUserSigned;

  const handleContinue = () => {
    if (readOnly) {
      return;
    }
    if (!readyToSign) {
      navigateToBundleView({ bundleId: id });
    } else {
      initiateSigningForBundle();
    }
  };

  const contentProps = {
    documentBundle,
    viewer,
    initiatingSigning,
    onContinue: handleContinue,
    isEsign,
  };

  const getUnactionableBodyContent = () => {
    // Transaction is not active
    if (!isActive(activation)) {
      return <NotActive {...contentProps} />;
    }

    // Transaction is expired and not complete
    if (isExpired && state !== OrgTransactionStates.COMPLETED) {
      return <Expired {...contentProps} />;
    }

    // Transaction is completed
    if (state === OrgTransactionStates.COMPLETED) {
      return <Complete {...contentProps} />;
    }

    if (currentUserSigned) {
      return <AwaitingOtherSigners {...contentProps} />;
    }

    // Transaction is currently being signed by another party
    return <UnableToSign {...contentProps} />;
  };

  const readyToSignCtaText = documentBundle.availableForSigning ? (
    <FormattedMessage
      id="a4e25ac6-4831-4685-8254-be295b0fed6c"
      defaultMessage="{requiresNsaMeeting, select, true{Get it notarized} other {Sign now}}"
      values={{ requiresNsaMeeting: documentBundle.organizationTransaction.requiresNsaMeeting }}
    />
  ) : (
    <FormattedMessage
      id="00e4fa2d-9eab-4a07-a0f6-cdf10a25e950"
      defaultMessage="Preview Documents"
    />
  );

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.containerWrapper}>
        <Container className={Styles.container}>
          <Row>
            {readyToSign ? (
              <ReadyToSign
                {...contentProps}
                ctaText={readyToSignCtaText}
                isMobile={isMobile}
                handleBlockedAction={handleBlockedAction}
              />
            ) : (
              <Column
                xs={12}
                className={classnames(
                  isSmall ? Styles.columnSmall : Styles.column,
                  Styles.columnCentered,
                )}
              >
                <WelcomeText />
                {getUnactionableBodyContent()}
              </Column>
            )}
          </Row>
        </Container>
        <LandingCustomBlock organizationBrand={organizationBrand} />
        <Container className={Styles.container}>
          <LandingContacts contacts={organizationTransaction.contacts} />
          <LandingSupportInfo />
          <ProofFooter topMargin />
        </Container>
      </div>
      {isMobile && readyToSign && (
        <div className={Styles.mobileFooter}>
          <Button
            automationId={"get-started-button"}
            className={Styles.documentMessageButton}
            onClick={() => handleBlockedAction(handleContinue)}
            isLoading={initiatingSigning}
            buttonColor="action"
            variant="primary"
          >
            {readyToSignCtaText}
          </Button>
          {!requiresNsaMeeting && <NoMeetingWarning totalCount={documents.totalCount} />}
        </div>
      )}
    </div>
  );
}

function RealEstateLandingWrapper(props: WrapperProps) {
  const { documentBundle, viewer, readOnly, isEsign, handleBlockedAction } = props;
  return (
    <InitiateSigning viewer={viewer} documentBundle={documentBundle}>
      {({ loading, initiateSigningForBundle }) => (
        <LandingWrapper skipExpiredOrInactiveCheck {...props}>
          {() => (
            <RealEstateLanding
              viewer={viewer}
              documentBundle={documentBundle}
              initiateSigningForBundle={initiateSigningForBundle}
              initiatingSigning={loading}
              readOnly={readOnly}
              isEsign={isEsign}
              handleBlockedAction={handleBlockedAction}
            />
          )}
        </LandingWrapper>
      )}
    </InitiateSigning>
  );
}

export { RealEstateLandingWrapper as RealEstateLanding };
