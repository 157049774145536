import "common/mortgage/transactions/edit/sub_forms/details/index.scss";

import { PureComponent, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { MortgageTransactionType } from "graphql_globals";
import TextField from "common/form/fields/text";
import StyledTransactionType from "common/mortgage/transactions/form/styled_transaction_type";
import { StyledTextInput } from "common/form/inputs/text";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import SelectField from "common/form/fields/select";
import TipWell from "common/core/tip_well";
import { ADDRESS_FIELDS } from "common/form/sub_forms/address";
import Button from "common/core/button";
import Link from "common/core/link";
import FormRow from "common/form/elements/row";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import PropertyAddressForm from "common/mortgage/transactions/edit/sub_forms/property_address";
import subForm from "common/form/enhancers/sub_form";
import { QueryWithLoading } from "util/graphql/query";
import { getRequirements } from "common/mortgage/transactions/requirements_service/service";
import { composeValidators } from "util/form";
import { validatePresence, validateIf } from "validators/form";
import {
  ALL_MORTGAGE_TRANSACTION_TYPES,
  MORTGAGE_TRANSACTION_REQUIREMENTS,
} from "constants/transaction";
import { PAPER_RECORDING_BLOG_POST } from "constants/marketing";
import validatePointOfContact from "common/mortgage/transactions/form/points_of_contact/point_of_contact/validate";
import validateTitleCollabContact from "lender_portal/transactions/edit/title_collaborator/validate";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import { isRequired, isConditional } from "util/requirements";
import { useFeatureFlag } from "common/feature_gating";
import { CONVERT_RON_TO_HYBRID } from "constants/feature_gates";
import { usePermissions } from "common/core/current_user_role";
import { supportsHybrid } from "common/mortgage/transactions/setup/closing_type";

import TransactionDetailsSectionQuery from "./transaction_details_section_query.graphql";

const messages = defineMessages({
  loanNumber: {
    id: "4f60494e-7b39-456a-9068-fa2c75a23a79",
    defaultMessage: "Loan Number",
  },
  edit: {
    id: "13726b78-814e-4259-847d-cbb8bcb59e17",
    defaultMessage: "Edit",
  },
  convertToHybrid: {
    id: "d3665d55-dda7-44ec-8d32-6b172cdaeab7",
    defaultMessage: "Convert to Hybrid",
  },
  fileNumber: {
    id: "f26989d1-e1c8-48e4-aa8c-874ee78225a9",
    defaultMessage: "File Number",
  },
  transactionName: {
    id: "3b922dc7-ef9d-464f-8d58-979723209d9d",
    defaultMessage: "Transaction Name",
  },
  transactionType: {
    id: "4e475840-f6eb-4642-8682-3eeb4e20d882",
    defaultMessage: "Transaction Type",
  },
  propertyAddress: {
    id: "724a92f5-2638-4b3b-bd09-b0fe5fa5513d",
    defaultMessage: "Property Address",
  },
  addressLine1: {
    id: "a39e48f8-58e4-497e-9b26-980e09e5f88b",
    defaultMessage: "Address Line 1",
  },
  addressLine2: {
    id: "d37e8487-e44f-41d8-afcd-7d924f8deee8",
    defaultMessage: "Address Line 2",
  },
  city: {
    id: "3fb07c5c-5d75-4fc5-8034-277e9c73f882",
    defaultMessage: "City",
  },
  country: {
    id: "1a350cbd-0456-4da9-9299-516ac3f85d85",
    defaultMessage: "Country",
  },
  state: {
    id: "cd3ac3b7-f381-436d-af6b-30b120b2afbd",
    defaultMessage: "State",
  },
  zip: {
    id: "7e8b1631-4989-4f38-9a00-dc4089ce5b42",
    defaultMessage: "ZIP/Postal",
  },
  titleUnderwriter: {
    id: "cc347eeb-6465-4496-af96-942ce0319508",
    defaultMessage: "Title Underwriter",
  },
  titleAgency: {
    id: "6b82dd1c-f2e0-4cd1-ba7c-5783a8e028bf",
    defaultMessage: "Title Agency",
  },
  paperRecordingTipHeader: {
    id: "92a1a7e5-b98d-4aea-ada5-6ab4cac627f5",
    defaultMessage: "How do I close online in a paper recording county?",
  },
  paperRecordingTip: {
    id: "871e34af-2537-4df4-a043-4d6540b0378b",
    defaultMessage:
      "Your customers can complete a fully online closing in any county in {state} with the addition of a wet-signed Declaration of Authenticity.",
  },
  recordingLocation: {
    id: "4b2660d8-0bfe-4053-a369-db4fa8a26be8",
    defaultMessage: "Recording Location",
  },
});

const validateLenderPointOfContacts =
  ({ hasCollaboratorSection = false, isFullRON = false, isRealEstateCollabEnabled = false } = {}) =>
  (values) => {
    const pointsOfContact = values.pointsOfContact;
    const titleAgency = values.titleAgency;
    const titleAgentLookup = values.titleAgentLookup;
    if (!pointsOfContact) {
      return {};
    }
    const errors = pointsOfContact.map((pointOfContact, index) => {
      if (hasCollaboratorSection && index === 0) {
        return validateTitleCollabContact(
          pointOfContact,
          titleAgency,
          isFullRON,
          titleAgentLookup,
          isRealEstateCollabEnabled,
        );
      }
      return validatePointOfContact(pointOfContact);
    });
    return {
      pointsOfContact: errors,
    };
  };

export const validationRules = (_values, props) =>
  composeValidators(
    validatePresence({ field: "transactionType", label: "Transaction type" }),
    validateIf({
      field: "fileNumber",
      condition: () => {
        const transactionType = props?.transaction?.transactionType;
        const req = getRequirements(transactionType, "lender");
        return isRequired(req[MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]);
      },
      validation: validatePresence({ field: "fileNumber", label: "File Number" }),
    }),
    validateIf({
      field: "loanNumber",
      condition: () => {
        const transactionType = props?.transaction?.transactionType;
        const req = getRequirements(transactionType, "lender");
        return (
          isRequired(req[MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]) ||
          transactionType === MortgageTransactionType.loan_mod_borrower
        );
      },
      validation: validatePresence({ field: "loanNumber", label: "Loan Number" }),
    }),
    validateIf({
      field: "titleUnderwriter",
      condition: () => {
        const { transaction, canSendToSigner } = props;
        const req = getRequirements(transaction?.transactionType, "lender");
        // Only validate if the user is sending to the signer
        return (
          canSendToSigner &&
          !transaction?.underwriterOverride &&
          isRequired(req[MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER])
        );
      },
      validation: validatePresence({ field: "titleUnderwriter", label: "Title Underwriter" }),
    }),
    validateIf({
      field: "recordingLocation",
      condition: () => {
        const transactionType = props?.transaction?.transactionType;
        const req = getRequirements(transactionType, "lender");
        return isRequired(req[MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]);
      },
      validation: validatePresence({ field: "recordingLocation", label: "Recording location" }),
    }),
    validateLenderPointOfContacts(props),
  );

const titleUnderwriterItems = (underwriters) =>
  (underwriters || []).map((underwriter) => {
    return { value: underwriter.id, label: underwriter.name };
  });

function EditTransactionTypeButton(props) {
  const {
    intl,
    onConvertToHybrid,
    transaction,
    editingTransactionType,
    setEditingTransactionType,
    canSendOrderToSigner,
  } = props;
  const { transactionType } = transaction;
  const showConvertToHybrid =
    useFeatureFlag(CONVERT_RON_TO_HYBRID) &&
    !isHybridTransactionType(transactionType) &&
    supportsHybrid(transactionType);

  if (canSendOrderToSigner && !editingTransactionType) {
    return (
      <Button
        buttonColor="action"
        variant="secondary"
        automationId="navigate-to-edit-transaction-type"
        className="TransactionDetailsSection--EditTransactionType--Button"
        onClick={() => {
          setEditingTransactionType(true);
        }}
      >
        {intl.formatMessage(messages.edit)}
      </Button>
    );
  } else if (showConvertToHybrid) {
    return (
      <Button
        buttonColor="action"
        variant="secondary"
        automationId="convert-to-hybrid-button"
        className="TransactionDetailsSection--EditTransactionType--Button"
        onClick={onConvertToHybrid}
      >
        {intl.formatMessage(messages.convertToHybrid)}
      </Button>
    );
  }
  return null;
}

function TransactionDetailsSectionContent(props) {
  const {
    intl,
    transaction,
    formName,
    titleUnderwriters,
    recordingLocations,
    readOnly,
    canSendToSigner,
  } = props;
  const [editingTransactionType, setEditingTransactionType] = useState(false);
  const { hasPermissionFor } = usePermissions();
  const { transactionType, recordingLocation, titleAgency, underwriterOverride } = transaction;

  const requirements = getRequirements(transactionType, "lender");
  const titleUnderwriterRequired =
    !underwriterOverride &&
    isRequired(requirements[MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]);
  const fileNumberRequired = isRequired(
    requirements[MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER],
  );
  const loanNumberRequired = isRequired(
    requirements[MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER],
  );

  const disabledFields =
    transaction.transactionType === MortgageTransactionType.other || editingTransactionType
      ? []
      : [
          ADDRESS_FIELDS.LINE_1,
          ADDRESS_FIELDS.LINE_2,
          ADDRESS_FIELDS.CITY,
          ADDRESS_FIELDS.STATE,
          ADDRESS_FIELDS.POSTAL,
        ];

  const underwritingTransaction = isConditional(
    getRequirements(transactionType, "lender")[MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER],
  );
  const showTitleAgency = titleAgency && underwritingTransaction;
  const showTitleUnderwriters = recordingLocation && underwritingTransaction;
  const canSendOrderToSigner = hasPermissionFor("manageOpenOrders");

  const paperRecordingTipwell =
    recordingLocation && !recordingLocation.erecordingSupported ? (
      <TipWell heading={intl.formatMessage(messages.paperRecordingTipHeader)}>
        {intl.formatMessage(messages.paperRecordingTip, { state: recordingLocation.usState.name })}{" "}
        <Link href={PAPER_RECORDING_BLOG_POST}>
          <FormattedMessage
            id="fd15d12b-c15c-4bf6-9807-ee57788bd9eb"
            defaultMessage="Learn more."
          />
        </Link>
      </TipWell>
    ) : null;

  return (
    <div>
      <SubForm className="TransactionDetailsSection">
        <SubFormSection>
          <FormRow>
            <div className="TransactionDetailsSection--EditTransactionType">
              <StyledTransactionType
                id="transactionType"
                name="transactionType"
                data-automation-id="transaction-type"
                transactionTypes={ALL_MORTGAGE_TRANSACTION_TYPES}
                placeholder={intl.formatMessage(messages.transactionType)}
                placeholderAsLabel
                disabled={!editingTransactionType}
              />
              <EditTransactionTypeButton
                {...props}
                editingTransactionType={editingTransactionType}
                setEditingTransactionType={setEditingTransactionType}
                canSendOrderToSigner={canSendOrderToSigner}
              />
            </div>
            <FormGroupErrors
              fields={["transactionType"]}
              groupClassName="TransactionDetailsSection--FormGroup"
              errorClassName="TransactionDetailsSection--ValidationMessage"
            />
          </FormRow>
        </SubFormSection>
        <SubFormSection className="NoMargin">
          {showTitleAgency && (
            <FormRow>
              <StyledTextInput
                id="titleAgency"
                name="titleAgency"
                data-automation-id="title-agency-field"
                label={intl.formatMessage(messages.titleAgency)}
                value={titleAgency.name}
                disabled
              />
            </FormRow>
          )}
          {showTitleUnderwriters && (
            <FormGroup fields={["titleUnderwriter"]} disableFormRowStyle>
              <SelectField
                id="titleUnderwriter"
                name="titleUnderwriter"
                data-automation-id="title-underwriter-field"
                placeholder={intl.formatMessage(messages.titleUnderwriter)}
                items={titleUnderwriterItems(titleUnderwriters)}
                placeholderAsLabel
                displayRequiredAsterisk={titleUnderwriterRequired && canSendToSigner}
                useStyledInput
              />
              <FormGroupErrors
                fields={["titleUnderwriter"]}
                groupClassName="TransactionDetailsSection--FormGroup"
                errorClassName="TransactionDetailsSection--ValidationMessage"
              />
            </FormGroup>
          )}
          <FormRow>
            <FormGroup
              fields={["fileNumber"]}
              disableFormRowStyle
              errorClassName="TransactionDetailsSection__validationFailed"
            >
              <TextField
                id="fileNumber"
                name="fileNumber"
                data-automation-id="file-number"
                placeholder={intl.formatMessage(messages.fileNumber)}
                placeholderAsLabel
                useStyledInput
                displayRequiredAsterisk={fileNumberRequired}
              />
              <FormGroupErrors
                fields={["fileNumber"]}
                groupClassName="TransactionDetailsSection--FormGroup"
                errorClassName="TransactionDetailsSection--ValidationMessage"
              />
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup
              fields={["loanNumber"]}
              disableFormRowStyle
              errorClassName="TransactionDetailsSection__validationFailed"
            >
              <TextField
                id="loanNumber"
                name="loanNumber"
                data-automation-id="loan-number"
                placeholder={intl.formatMessage(messages.loanNumber)}
                placeholderAsLabel
                displayRequiredAsterisk={loanNumberRequired}
                useStyledInput
                disabled={readOnly}
              />
              <FormGroupErrors
                fields={["loanNumber"]}
                groupClassName="TransactionDetailsSection--FormGroup"
                errorClassName="TransactionDetailsSection--ValidationMessage"
              />
            </FormGroup>
          </FormRow>
        </SubFormSection>
      </SubForm>
      <SubForm className="PropertyAddressDetailsSection">
        <SubFormSection tipWell={paperRecordingTipwell}>
          <PropertyAddressForm
            disabledFields={disabledFields}
            disableRecordingLocation={transaction.transactionType === MortgageTransactionType.other}
            initialRecordingLocation={recordingLocation}
            initialRecordingLocations={recordingLocations}
            formName={formName}
          />
        </SubFormSection>
      </SubForm>
    </div>
  );
}

TransactionDetailsSectionContent.propTypes = {
  intl: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onConvertToHybrid: PropTypes.func.isRequired,
};

class TransactionDetailsSection extends PureComponent {
  render() {
    const {
      transaction: { id, propertyAddress },
      canSendToSigner,
    } = this.props;
    const formattedPropertyAddress = {
      city: propertyAddress?.city,
      country: propertyAddress?.country,
      line1: propertyAddress?.line1,
      line2: propertyAddress?.line2,
      postal: propertyAddress?.postal,
      state: propertyAddress?.state,
    };
    return (
      <QueryWithLoading
        query={TransactionDetailsSectionQuery}
        variables={{
          transactionId: id,
          propertyAddress: formattedPropertyAddress,
          searchRecordingLocations: Boolean(propertyAddress),
        }}
      >
        {({ data }) => {
          return (
            <TransactionDetailsSectionContent
              {...this.props}
              titleUnderwriters={data?.node?.publicEligibleTitleUnderwriters || []}
              recordingLocations={data?.viewer?.recordingLocationEligibilities || []}
              canSendToSigner={canSendToSigner}
            />
          );
        }}
      </QueryWithLoading>
    );
  }
}

export default subForm({
  getValuesFor: ["transactionType"],
})((props) => <TransactionDetailsSection {...props} intl={useIntl()} navigate={useNavigate()} />);
