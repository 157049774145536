/**
 * Notary note comes from bundle.instructions. The list
 * is either empty or contains one item during transaction creation process.
 *
 * @params {Array.<string>} instructions A list of notary notes.
 * @return {object} A DocumentBundleInstruction object.
 */
export function getNoteFromInstructions(instructions) {
  return instructions.length ? instructions[0] : null;
}
