import "common/app_frame/index.scss";

import { memo, type ReactNode } from "react";

import AppFrame from "common/app_frame/";
import UpdateAppBanner from "common/banners/update_app_banner";
import OrganizationNavHeader from "common/organization/nav_header";
import { AppFrameNotaryOnboardingBanner } from "common/banners/onboarding_banner";

import type { ViewerLenderAppFrame as Viewer } from "./app_frame_fragment.graphql";

type Props = {
  viewer: Viewer;
  children: ReactNode;
};

function LenderAppFrame({ children, viewer }: Props) {
  const user = viewer.user!;
  return (
    <AppFrame
      content={<OrganizationNavHeader user={user} />}
      user={user}
      showOrgName
      banners={
        <>
          <UpdateAppBanner />
          {user.notaryProfile && (
            <AppFrameNotaryOnboardingBanner notaryProfile={user.notaryProfile} />
          )}
        </>
      }
    >
      {children}
    </AppFrame>
  );
}

export default memo(LenderAppFrame);
