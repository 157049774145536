import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  PROOF_TRANSACTIONS,
  LENDER_ESIGN,
  HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN,
  HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN,
  IDENTIFY_TRANSACTIONS,
} from "constants/feature_gates";
import {
  ESIGN_TRANSACTION_TYPE,
  IDENTIFY_TRANSACTION_TYPE,
  PROOF_TRANSACTION_TYPE,
} from "constants/transaction";
import Button from "common/core/button";
import PopoutMenu from "common/core/popout_menu";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";
import { useFeatureFlag } from "common/feature_gating";
import { useSignTransactionsEnabled } from "util/feature_detection";

import { DashboardDropdownIcon, DashboardDropdownTitle } from "../dropdown";
import Styles from "./index.module.scss";

type Props = {
  showPlaceOrderUI: boolean;
  handleRealEstateOption: () => void;
  orgIsActive?: boolean;
  portal: "title" | "lender";
};

export function RealEstateNewTransactionDropdown({
  showPlaceOrderUI,
  handleRealEstateOption,
  orgIsActive = true,
  portal,
}: Props) {
  const navigate = useNavigate();
  const signTransactionsEnabled = useSignTransactionsEnabled();
  const proofTransactionsEnabled = useFeatureFlag(PROOF_TRANSACTIONS) && !signTransactionsEnabled;
  const lenderEsignEnabled = useFeatureFlag(LENDER_ESIGN);
  const showEsignOption = !useFeatureFlag(HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN);
  const showNotarizationOption = !useFeatureFlag(HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN);
  const showIdentifyOption = useFeatureFlag(IDENTIFY_TRANSACTIONS);

  const realClosingContent =
    portal === "title" ? (
      <FormattedMessage
        id="55fbf20b-6dbb-4bb8-9b9f-c38ffee510e0"
        defaultMessage="Home purchases (cash buyer and seller) and HELOCs"
      />
    ) : (
      // lender
      <FormattedMessage
        id="8ab49151-ade2-4f3c-8be6-2e79428d502e"
        defaultMessage="HELOCs, home purchases, and refinances"
      />
    );

  return (
    <PopoutMenu
      placement="bottomRight"
      className={Styles.dropdownButton}
      target={
        <Button
          disabled={!orgIsActive}
          disabledHint={
            !orgIsActive && (
              <FormattedMessage
                id="274c6a4f-e28c-4529-af41-635bfbd3ce24"
                defaultMessage="You cannot send a new transaction because this organization is disabled."
              />
            )
          }
          disabledHintPlacement="bottom"
          fullwidth
          buttonColor="action"
          variant="primary"
          automationId="new-transaction"
        >
          {showPlaceOrderUI ? (
            <FormattedMessage
              id="7d44f0dd-8560-4714-ad84-38163cc0bae8"
              defaultMessage="Place an order"
            />
          ) : (
            <FormattedMessage
              id="274c6a4f-e28c-4529-af41-635bfbd3ce24"
              defaultMessage="Send new transaction"
            />
          )}
          <DashboardDropdownIcon />
        </Button>
      }
    >
      {() => (
        <>
          <DashboardDropdownTitle>
            <FormattedMessage
              id="225d6f7e-5d31-4f4a-be27-4d978ba70357"
              defaultMessage="Select Transaction Type"
            />
          </DashboardDropdownTitle>
          <PopoutMenuMultilineItem
            link
            iconName="address"
            onClick={handleRealEstateOption}
            primaryContent={
              <FormattedMessage
                id="61e02fbe-989d-48d7-8337-78d0d9a90f8b"
                defaultMessage="Real estate closing"
              />
            }
            withFixedWidth
            secondaryContent={realClosingContent}
            data-automation-id="new-real-estate-closing"
          />
          {showNotarizationOption && (
            <PopoutMenuMultilineItem
              link
              withFixedWidth
              iconName="notarize-line"
              onClick={() => {
                navigate("/transaction/create");
              }}
              primaryContent={
                <FormattedMessage
                  id="bc9612ef-46c6-4c31-a15e-b7b6a06ca5d2"
                  defaultMessage="Notarization request"
                />
              }
              secondaryContent={
                <FormattedMessage
                  id="1b0050ac-b712-4f4d-826e-6ed94e3e0060"
                  defaultMessage="Remote notarizations for non-closings"
                />
              }
              data-automation-id="new-other-transaction"
            />
          )}
          {proofTransactionsEnabled && (
            <PopoutMenuMultilineItem
              link
              withFixedWidth
              iconName="evault"
              onClick={() => {
                navigate(`/transaction/create?type=${PROOF_TRANSACTION_TYPE}`);
              }}
              primaryContent={
                <FormattedMessage
                  id="5ae547d9-dd72-4837-ad97-6c23b4de3821"
                  defaultMessage="Proof"
                />
              }
              secondaryContent={
                <FormattedMessage
                  id="e2ed00c4-7b50-4c67-8e54-e6c589165e07"
                  defaultMessage="Secure, identity-verified signatures without notarization"
                />
              }
              data-automation-id="new-proof-transaction"
            />
          )}
          {showEsignOption && (lenderEsignEnabled || portal === "title") && (
            <PopoutMenuMultilineItem
              link
              withFixedWidth
              iconName="toolbar-signature"
              onClick={() => {
                navigate(`/transaction/create?type=${ESIGN_TRANSACTION_TYPE}`);
              }}
              primaryContent={
                <FormattedMessage
                  id="f76b34ce-21dc-45a0-afb1-76b97562ba7a"
                  defaultMessage="eSignature request"
                />
              }
              secondaryContent={
                <FormattedMessage
                  id="89a3b085-1119-4d10-8521-6473e5734c3f"
                  defaultMessage="Digital signatures without identity verification or notarization"
                />
              }
              data-automation-id="new-esign-transaction"
            />
          )}
          {showIdentifyOption && (
            <PopoutMenuMultilineItem
              link
              withFixedWidth
              iconName="secure"
              onClick={() => {
                navigate(`/transaction/create?type=${IDENTIFY_TRANSACTION_TYPE}`);
              }}
              primaryContent={
                <FormattedMessage
                  id="f76b34ce-21dc-45a0-afb1-76b97562ba7a"
                  defaultMessage="Identify"
                />
              }
              secondaryContent={
                <FormattedMessage
                  id="89a3b085-1119-4d10-8521-6473e5734c3f"
                  defaultMessage="Verify the identity of a signer"
                />
              }
              data-automation-id="new-identify-transaction"
            />
          )}
        </>
      )}
    </PopoutMenu>
  );
}
