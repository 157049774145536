import { memo, useState, useRef, type ChangeEvent, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Button from "common/core/button";
import Icon from "common/core/icon";
import { simpleAssetUpload } from "util/uploader";
import Processing from "assets/images/processing.svg";

import Styles from "./index.module.scss";

type Props = {
  onChange: (newValue: string | null, file?: File) => void;
  persistedValue?: ReactNode;
};
type InnerProps = Props & {
  uploadFn: (params: { asset: File }) => Promise<string>;
  acceptedFileTypes?: string;
};
type FileState =
  | { kind: "empty" }
  | { kind: "uploading"; file: File }
  | { kind: "done"; file: File }
  | { kind: "error"; file: File };

function InnerUploader({ onChange, acceptedFileTypes, persistedValue, uploadFn }: InnerProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileState, setFileState] = useState<FileState>({ kind: "empty" });
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const file = target.files![0];
    onChange(null);
    setFileState({ kind: "uploading", file });
    uploadFn({ asset: file })
      .then((key) => {
        setFileState({ kind: "done", file });

        onChange(key, file);
      })
      .catch(() => {
        setFileState({ kind: "error", file });
      });
  };
  const isEmpty = fileState.kind === "empty";
  const isReplacement = Boolean(persistedValue);
  return (
    <div className={classnames(Styles.uploader, fileState.kind === "error" && Styles.alert)}>
      <input type="file" hidden accept={acceptedFileTypes} ref={inputRef} onChange={handleChange} />
      {isEmpty && (
        <>
          {isReplacement && (
            <>
              <Icon className={Styles.icon} name="blank-doc" />
              <div className={Styles.filename}>{persistedValue}</div>
            </>
          )}
          <Button
            buttonColor="action"
            variant="secondary"
            onClick={() => inputRef.current!.click()}
          >
            <FormattedMessage
              id="670fa868-05b4-4cdd-b8a3-7b1888aed9df"
              defaultMessage="{isReplacement, select, true {Replace} other {Upload}}"
              values={{ isReplacement }}
            />
          </Button>
        </>
      )}
      {fileState.kind !== "empty" && (
        <>
          {fileState.kind === "uploading" ? (
            <img src={Processing} className={Styles.spinAnimation} alt="Uploading" />
          ) : (
            <Icon className={Styles.icon} name="blank-doc" />
          )}
          <div className={Styles.filename}>{fileState.file.name}</div>
          <Button
            variant="tertiary"
            buttonColor="action"
            onClick={() => {
              inputRef.current!.value = "";
              onChange(null);
              setFileState({ kind: "empty" });
            }}
          >
            <FormattedMessage id="a6ae5015-d867-4eb0-a880-8721d86eac22" defaultMessage="Delete" />
          </Button>
        </>
      )}
    </div>
  );
}

export const NotaryProfileWizardUploader = memo(InnerUploader);

export function NotaryProfileWizardAssetUploader({ onChange, persistedValue }: Props) {
  return (
    <NotaryProfileWizardUploader
      onChange={onChange}
      persistedValue={persistedValue}
      uploadFn={simpleAssetUpload}
      acceptedFileTypes="image/png,image/jpeg,application/pdf"
    />
  );
}
