import { useEffect } from "react";

import type { Pendo } from "vendors/pendo";
import Env from "config/environment";
import { LENDER_TRANSACTION_TYPES, TITLE_MORTGAGE_TRANSACTION_TYPES } from "constants/transaction";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import {
  BUSINESS_CONFIG_IDS,
  REAL_ESTATE_ESIGN_CONFIG_IDS,
} from "common/transaction_creation/v3/config";
import { captureException } from "util/exception";
import request from "util/request";
import { CURRENT_PORTAL } from "constants/app_subdomains";

const { pendoIdPrefix } = Env;

declare const window: Window & { pendo?: Pendo };

const EVENTS = {
  ORGANIZATION_TRANSACTION_EDITOR_SEND_V2: "V2 Organization Transaction Editor Send",
  ORGANIZATION_TRANSACTION_SEND_NOTARIZATION_V2: "V2 Organization Transaction Send Notarization",
  ORGANIZATION_TRANSACTION_SEND_ESIGN_V2: "V2 Organization Transaction Send eSign",
  ORGANIZATION_TRANSACTION_SEND_PROOF_V2: "V2 Organization Transaction Send Proof",
  ORGANIZATION_TRANSACTION_SEND_REAL_CLOSING_V2:
    "V2 Organization Transaction Send Real Estate Closing",
  ORGANIZATION_TRANSACTION_SEND_REAL_HYBRID_V2:
    "V2 Organization Transaction Send Real Estate Hybrid",
  ORGANIZATION_TRANSACTION_SEND_REAL_ESIGN_V2: "V2 Organization Transaction Send Real Estate Esign",
  ORGANIZATION_TRANSACTION_SEND_REAL_NOTARIZATION_V2:
    "V2 Organization Transaction Send Real Estate Notarization",
  ORGANIZATION_TRANSACTION_SEND_REAL_PROOF_V2: "V2 Organization Transaction Send Real Estate Proof",
  ORGANIZATION_TRANSACTION_SEND_NOTARIZATION_V3: "V3 Organization Transaction Send Notarization",
  ORGANIZATION_TRANSACTION_SEND_ESIGN_V3: "V3 Organization Transaction Send eSign",
  ORGANIZATION_TRANSACTION_SEND_PROOF_V3: "V3 Organization Transaction Send Proof",
  ORGANIZATION_TRANSACTION_SEND_IDENTIFY_V3: "V3 Organization Transaction Send Identify",
  ORGANIZATION_TRANSACTION_EDITOR_SEND_V3: "V3 Organization Transaction Editor Send",
  ORGANIZATION_TRANSACTION_SEND_REAL_CLOSING_V3:
    "V3 Organization Transaction Send Real Estate Closing",
  ORGANIZATION_TRANSACTION_SEND_REAL_HYBRID_V3:
    "V3 Organization Transaction Send Real Estate Hybrid",
  ORGANIZATION_TRANSACTION_SEND_REAL_ESIGN_V3: "V3 Organization Transaction Send Real Estate Esign",
  ORGANIZATION_TRANSACTION_SEND_REAL_PROOF_V3: "V3 Organization Transaction Send Real Estate Proof",
  ORGANIZATION_TRANSACTION_SEND_REAL_IDENTIFY_V3:
    "V3 Organization Transaction Send Real Estate Identify",
} as const;

export function getEventFromFormV2(
  form: "real_estate_esign" | "real_estate_proof" | "esign" | "proof" | "notarization",
) {
  switch (form) {
    case "esign":
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_ESIGN_V2;
    case "proof":
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_PROOF_V2;
    case "notarization":
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_NOTARIZATION_V2;
    case "real_estate_esign":
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_ESIGN_V2;
    case "real_estate_proof":
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_PROOF_V2;
    default:
      return EVENTS.ORGANIZATION_TRANSACTION_EDITOR_SEND_V2;
  }
}

export function getEventFromFormV3(form: string) {
  // This won't work long term, when workflows become a thing and config IDs can be more variable
  // But we also might not need these at this point. They're just to trigger the Pendo surveys at the
  // moment and Pendo admins are aware of the limitations.
  if (
    Object.values({
      ...TITLE_MORTGAGE_TRANSACTION_TYPES,
      ...LENDER_TRANSACTION_TYPES,
    }).includes(form)
  ) {
    return EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_CLOSING_V3;
  }

  if (isHybridTransactionType(form)) {
    return EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_HYBRID_V3;
  }

  switch (form) {
    case BUSINESS_CONFIG_IDS.ESIGN:
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_ESIGN_V3;
    case BUSINESS_CONFIG_IDS.PROOF:
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_PROOF_V3;
    case BUSINESS_CONFIG_IDS.IDENTIFY:
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_IDENTIFY_V3;
    case BUSINESS_CONFIG_IDS.NOTARIZATION:
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_NOTARIZATION_V3;
    case REAL_ESTATE_ESIGN_CONFIG_IDS.ESIGN:
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_ESIGN_V3;
    case REAL_ESTATE_ESIGN_CONFIG_IDS.PROOF:
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_PROOF_V3;
    case REAL_ESTATE_ESIGN_CONFIG_IDS.IDENTIFY:
      return EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_IDENTIFY_V3;
    default:
      return EVENTS.ORGANIZATION_TRANSACTION_EDITOR_SEND_V3;
  }
}

export function getTitleEventFromFormV2(form: string) {
  if (
    Object.values({
      ...TITLE_MORTGAGE_TRANSACTION_TYPES,
      ...LENDER_TRANSACTION_TYPES,
    }).includes(form)
  ) {
    return EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_CLOSING_V2;
  }

  if (isHybridTransactionType(form)) {
    return EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_HYBRID_V2;
  }

  return EVENTS.ORGANIZATION_TRANSACTION_EDITOR_SEND_V2;
}

type PendoUser = {
  id: string;
  email?: string | null;
  organization?: { id: string } | null;
};

export function isPendoReady() {
  return window.pendo?.isReady();
}

function isAnonymousVisitor() {
  return window.pendo?.isAnonymousVisitor(window.pendo.getVisitorId());
}

export function pendoInitialize(user?: PendoUser | null) {
  if (!window.pendo) {
    return;
  }

  // Pendo is already initialized and they've already been identified - noop
  if (isPendoReady() && !isAnonymousVisitor()) {
    return;
  }

  if (user) {
    // we need to assign email to visitor id for internal employees, since that is the only way
    // we can exclude them (pendo only allows exclude by visitor id, but not other metadata fields)
    const userId =
      user.email?.includes("@proof.com") || user.email?.includes("@notarize.com")
        ? user.email
        : user.id;
    const orgId = user.organization?.id;

    const data = {
      visitor: { id: pendoIdPrefix ? `${pendoIdPrefix}-${userId}` : userId, email: user.email },
      ...(orgId && {
        account: { id: pendoIdPrefix ? `${pendoIdPrefix}-${orgId}` : orgId },
      }),
    };

    // If Pendo is already initialized but hasn't been given a user, identify the user with Pendo
    // This is specific to the pre-documents flow
    if (CURRENT_PORTAL === "customer" && isPendoReady()) {
      window.pendo.identify(data);
    } else {
      window.pendo.initialize(data);
    }
  } else {
    // Pendo-specific string for anonymous ID
    window.pendo.initialize({ visitor: { id: "VISITOR-UNIQUE-ID" } });
  }
}

export function pendoUpdateMetadata(email: string | null, gid: string | null) {
  if (window.pendo) {
    request("post", "pendo/set_metadata_fields", {
      gid,
      email,
      id_prefix: pendoIdPrefix,
    }).catch((error) => {
      // If this accidentally got called in an unathenticated context we don't want to explode.
      captureException(error);
    });
  }
}

export function pendoTrack(
  event: (typeof EVENTS)[keyof typeof EVENTS],
  properties: Record<string, unknown> = {},
) {
  window.pendo?.track(event, properties);
}

export type PendoTransformsArg =
  | {
      attr: "search";
      action: "AllowOnlyKeys" | "ExcludeKeys" | "AddTo" | "Replace" | "Clear";
      data: string[] | Record<string, unknown> | (() => unknown);
    }
  | {
      attr: "search" | "pathname" | "hostname" | "href";
      action: "Replace";
      data: string;
    };

// https://agent.pendo.io/advanced/location/#pendolocationaddtransforms
function pendoAddTransforms(transforms: PendoTransformsArg[]) {
  window.pendo?.location.addTransforms(transforms);
}

export function pendoAddParamsToUrl(params: Record<string, string>) {
  if (!window.pendo) {
    return;
  }
  const currentUrl = window.pendo.location.getHref();
  // Edge case found in stress testing. If location isn't reading, don't want to
  // add to it.
  if (!currentUrl) {
    return;
  }

  const pendoParams: Record<string, string> = {};
  Object.keys(params).forEach((param) => {
    if (currentUrl.includes(param)) {
      return;
    }

    pendoParams[param] = params[param];
  });

  if (Object.keys(pendoParams).length) {
    pendoAddTransforms([
      {
        attr: "search",
        action: "AddTo",
        data: () => pendoParams,
      },
    ]);
  }
}

function pendoClearTransforms() {
  if (window.pendo?.location.getHref() !== window.location.href) {
    window.pendo?.location.clearTransforms();
  }
}

export function ClearPendoTransforms() {
  useEffect(() => {
    pendoClearTransforms();
  }, []);

  return null;
}
