import "./index.scss";

import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import PageModal from "common/organization/page_modal";
import DiscountCheck from "common/modals/discount_modal/discount_check";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { useActiveOrganization } from "common/account/active_organization";
import { usePermissions } from "common/core/current_user_role";

import SideBar from "./sidebar";
import SetupForm from "./setup_form";
import GetRealEstOrgForSetupQuery, {
  type GetRealEstOrgForSetup_viewer as Viewer,
  type GetRealEstOrgForSetup_organization_Organization as Organization,
} from "./index_query.graphql";

type LoadedProps = {
  organization: Organization;
  viewer: Viewer;
};

function OrganizationSetupLoaded({ viewer, organization }: LoadedProps) {
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();

  const isOrgAdmin = hasPermissionFor("editOrganizationApiKeys");

  useEffect(() => {
    if (!isOrgAdmin || organization.isSetup.complete) {
      navigate("/");
    }
  }, []);

  const handleOnChangesSaved = () => {
    navigate("/");
  };

  return (
    <PageModal
      sidebar={<SideBar verificationOfFactEnabled={organization.canRequireVerificationOfFact} />}
    >
      <div className="SetupForm-lead">
        <div className="SetupForm-lead--title">
          <span>
            <FormattedMessage
              id="44593a35-6d25-4844-8a04-2b3e201908ee"
              defaultMessage="Account Setup"
            />
          </span>
        </div>
        <div className="SetupForm-lead--header">
          <span>
            <FormattedMessage
              id="32791515-3662-468b-ac12-fd51029c2b8a"
              defaultMessage="Welcome to Proof! Tell us about yourself or your organization."
            />
            <br />
            <FormattedMessage
              id="54eb99e4-bfd1-4430-a743-50a52f27b0d5"
              defaultMessage="Accurate information builds trust when you ask people to sign your documents."
            />
          </span>
        </div>
        <div className="SetupForm-lead--details">
          <SetupForm
            admin={isOrgAdmin}
            viewer={viewer}
            organization={organization}
            onChangesSaved={handleOnChangesSaved}
          />
        </div>
        <DiscountCheck className="SetupForm--discount" />
      </div>
    </PageModal>
  );
}

function OrganizationSetup() {
  const [activeOrgId] = useActiveOrganization();
  const { data, loading } = useQuery(GetRealEstOrgForSetupQuery, {
    variables: { organizationId: activeOrgId! },
  });
  if (loading) {
    return <LoadingIndicator />;
  }
  if (data?.organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${data?.organization?.__typename}`);
  }
  return <OrganizationSetupLoaded viewer={data.viewer} organization={data.organization} />;
}

export default OrganizationSetup;
